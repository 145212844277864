import * as React from 'react';
import { Route } from 'react-router-dom';

import Organizations from './pages/Organizations';
import RefreshLocations from './pages/RefreshLications';
import Edit from './pages/Edit';
import Create from './pages/Create';
import Module from '../Connector';

export default new Module({
  route: [
    <Route exact={true} path="/organizations" component={Organizations} />,
    <Route exact={true} path="/organizations/edit/:id" component={Edit} />,
    <Route exact={true} path="/organizations/create" component={Create} />,
    <Route exact={true} path="/organizations/refresh-locations" component={RefreshLocations} />
  ]
});
