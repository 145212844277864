import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs } from '../../../common/components';
import styles from './organizations.module.scss';
import OrganizationList from '../../components/OrganizationList';
import { organizationListStore } from '../../stores';

const Organizations = () => {
  useEffect(() => {
    organizationListStore.loadList();
  }, []);
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Список організацій`}</title>
        <meta name="description" content={`${settings.appName} - Список організацій`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' }
          ]}
        />
        <OrganizationList />
      </div>
    </AppLayout>
  );
};

export default Organizations;
