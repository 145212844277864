import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Alert } from 'antd';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs } from '../../../common/components';
import styles from './create.module.scss';
import CreateOrganizationForm from '../../components/CreateOrganizationForm';

const Create = () => {
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Нова організація`}</title>
        <meta name="description" content={`${settings.appName} - Нова організація`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' },
            { name: 'Форма реєстрації організації', url: '/organizations/create' }
          ]}
        />
        <div className={styles.fromWrap}>
          <Alert
            message="Заповнить форму щоб додати нову організацію. Всі поля обов’язкові до заповнення."
            type="info"
            showIcon={true}
          />
          <CreateOrganizationForm />
        </div>
      </div>
    </AppLayout>
  );
};

export default Create;
