import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Alert } from 'antd';

import RefreshLicationsForm from '../../components/RefreshLocationsForm';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs } from '../../../common/components';
import styles from './refreshLocations.module.scss';

const RefreshLications = () => {
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Оновити список локацій`}</title>
        <meta name="description" content={`${settings.appName} - Оновити список локацій`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' },
            { name: 'Оновлення списку локацій', url: '/organizations/refresh-locations' }
          ]}
        />
        <div className={styles.fromWrap}>
          <Alert
            message="Оберіть зі списку потрібне меню, яке необхідне для оновлення всіх локацій."
            type="info"
            showIcon={true}
          />
          <RefreshLicationsForm />
        </div>
      </div>
    </AppLayout>
  );
};

export default RefreshLications;
