import { observable, action, runInAction } from 'mobx';

import { Menu } from '../types';

class ShowModalEditMenuStore {
  @observable
  public state: boolean | false = false;
  @observable
  public menu: Menu | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async isShow(state: boolean, menu: Menu) {
    this.loading = true;

    try {
      runInAction(() => {
        this.state = state;
        this.menu = menu;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new ShowModalEditMenuStore();
