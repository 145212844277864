import { observable, action, runInAction } from 'mobx';

import { Location, LocationValues } from '../types';

import history from '../../../history';
import { locationsService } from '../services';
import { organizationStore } from '../../organizations/stores';
import { menuStore } from '.';

class LocationStore {
  @observable
  public location: Location | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async load(locationId: number) {
    this.loading = true;

    try {
      const location = await locationsService.load(locationId);
      runInAction(() => {
        this.location = location;
      });
      if (this.location && this.location.organizationId) {
        organizationStore.load(this.location.organizationId);
      }
      if (this.location && this.location.menuId) {
        menuStore.load(this.location.menuId);
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async create(formValues: LocationValues) {
    this.loading = true;

    try {
      const location = await locationsService.create(formValues);
      runInAction(() => {
        this.location = location;
      });
      history.push(`/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async edit(locationId: number, formValues: LocationValues) {
    this.loading = true;

    try {
      const location = await locationsService.edit(locationId, formValues);
      runInAction(() => {
        this.location = location;
      });
      if (this.location) {
        locationsService.load(this.location.id);
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async delete(locationId: number) {
    this.loading = true;

    try {
      await locationsService.delete(locationId);
      history.push(`/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new LocationStore();
