import { observable, action, runInAction } from 'mobx';

import { locationsService } from '../services';
import { Location } from '../types';

class LocationsListStore {
  @observable
  public locations: Location[] | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async loadList() {
    this.loading = true;

    try {
      const locations = await locationsService.loadList();
      runInAction(() => {
        this.locations = locations;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new LocationsListStore();
