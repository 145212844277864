import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Alert } from 'antd';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs } from '../../../common/components';
import styles from './create.module.scss';
import CreateAdministratorForm from '../../components/CreateAdministratorForm';
import { organizationStore } from '../../../organizations/stores';

const Create = () => {
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Новий адміністратор`}</title>
        <meta name="description" content={`${settings.appName} - Новий адміністратор`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' },
            {
              name: `${organizationStore.organization && organizationStore.organization.name}`,
              url: `/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`
            },
            { name: 'Форма реєстрації адміністратора', url: '/administrators/create' }
          ]}
        />
        <div className={styles.fromWrap}>
          <Alert
            message="Заповнить форму щоб додати нового адміністратора. Всі поля обов’язкові до заповнення."
            type="info"
            showIcon={true}
          />
          <CreateAdministratorForm />
        </div>
      </div>
    </AppLayout>
  );
};

export default Create;
