import * as React from 'react';
import { Layout, Row } from 'antd';
import moment from 'moment';

import styles from './footer.module.scss';

const { Footer: AntdFooter } = Layout;

const Footer = () => (
  <AntdFooter className={styles.footerLayout}>
    <Row className={styles.footer}>
      <div>Super Touch Core. ©{moment().format('YYYY')} Cusbee.inc.</div>
    </Row>
  </AntdFooter>
);

export default Footer;
