import axios from 'axios';
import { notification, message } from 'antd';

import { LocationValues, Location } from '../types';
import settings from '../../../settings';

class LocationsService {
  public loadList = async (): Promise<Location[]> => {
    try {
      const { data } = await axios.get(`${settings.domain}/api/admin/location/all`);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити список локацій. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public load = async (locationId: number): Promise<Location> => {
    try {
      const { data } = await axios.get(`${settings.domain}/api/admin/location/${locationId}`);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити інформацію про локацію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public create = async (formValues: LocationValues) => {
    try {
      const { data } = await axios.post(`${settings.domain}/api/admin/location`, formValues);
      message.success('Локація успішно створена!', 5);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося створити локацію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public edit = async (locationId: number, formValues: LocationValues) => {
    try {
      const { data } = await axios.put(`${settings.domain}/api/admin/location/${locationId}`, formValues);
      message.success('Локація успішно оновлена!', 5);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося оновити локацію. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public delete = async (locationId: number) => {
    try {
      await axios.delete(`${settings.domain}/api/admin/location/${locationId}`);
      message.success('Локація успішно видалена!', 5);
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося видалити локацію. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new LocationsService();
