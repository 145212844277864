import React from 'react';
import { Modal } from 'antd';
import { withFormik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';

import { InputField } from '../../../common/components';
import './editMenu.global.scss';
import styles from './editMenu.module.scss';
import { MenuValues, Menu } from '../../types';
import { showModalEditMenuStore, menuStore } from '../../stores';

interface EditMenuOwnProps {
  menu: Menu;
}

const EditMenu = (props: EditMenuOwnProps & FormikProps<MenuValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue, menu } = props;
  const handleOk = () => {
    handleSubmit();
    if (showModalEditMenuStore.menu) {
      showModalEditMenuStore.isShow(false, showModalEditMenuStore.menu);
    }
  };
  const handleCancel = () => {
    if (showModalEditMenuStore.menu) {
      showModalEditMenuStore.isShow(false, showModalEditMenuStore.menu);
    }
  };
  return (
    <div>
      <Modal
        className="modalWrap"
        title="Змінити назву меню"
        visible={showModalEditMenuStore.state}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <form className={styles.form}>
          <InputField
            title={`Назва меню`}
            placeholder={'Мінімум 2, максимум 40 символів'}
            name="name"
            defaultValue={menu.name}
            value={values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />
        </form>
      </Modal>
    </div>
  );
};

const EditMenuWithFormik = withFormik<EditMenuOwnProps, MenuValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ menu }) => ({
    name: menu.name
  }),
  handleSubmit: (values, { props: { menu } }) => {
    menuStore.edit(menu.id, values);
  },
  displayName: 'Edit menu'
})(observer(EditMenu));

export default EditMenuWithFormik;
