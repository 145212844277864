import axios from 'axios';
import { notification, message } from 'antd';

import { OrganizationValues, Organization } from '../types';
import settings from '../../../settings';

class OrganizationsService {
    public load = async (organizationId: number): Promise<Organization> => {
        try {
            const { data } = await axios.get(
                `${settings.domain}/api/admin/organization/${organizationId}`
            );
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося завантажити інформацію про організацію. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public loadList = async (): Promise<Organization[]> => {
        try {
            const { data } = await axios.get(
                `${settings.domain}/api/admin/organization/all`
            );
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося завантажити список організацій. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public create = async (formValues: OrganizationValues) => {
        try {
            const { data } = await axios.post(
                `${settings.domain}/api/admin/organization`,
                formValues
            );
            message.success('Організація успішно створена!', 5);
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося створити організацію. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public edit = async (
        organizationId: number,
        formValues: OrganizationValues
    ) => {
        try {
            const { data } = await axios.put(
                `${settings.domain}/api/admin/organization/${organizationId}`,
                formValues
            );
            message.success('Організація успішно оновлена!', 5);
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося оновити організацію. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public delete = async (organizationId: number) => {
        try {
            await axios.delete(
                `${settings.domain}/api/admin/organization/${organizationId}`
            );
            message.success('Організація успішно видалена!', 5);
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося видалити організацію. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public getTypes = async () => {
        try {
            const { data } = await axios.get(
                `${settings.domain}/api/admin/organization/type`
            );
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося завантажити типи організації. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public updateWithoutRedirect = async (
        organizationId: number,
        otherOrganizationId: number
    ) => {
        try {
            const { data } = await axios.put(
                `${settings.domain}/api/admin/organization/swap-order?first-id=${organizationId}&second-id=${otherOrganizationId}`
            );
            return data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка.',
                description:
                    'Не вдалося змінити пріорітет організації. Спробуйте пізніше.',
            });
            throw error;
        }
    };
}

export default new OrganizationsService();
