import * as React from 'react';
import { Route } from 'react-router-dom';

import Create from './pages/Create';
import Edit from './pages/Edit';
import Module from '../Connector';

export default new Module({
  route: [
    <Route exact={true} path="/locations/create" component={Create} />,
    <Route exact={true} path="/locations/edit/:id" component={Edit} />
  ]
});
