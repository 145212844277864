import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';

import styles from './locationSelectField.module.scss';
import { InputFieldTitle } from '../../../common/components';
import { Location } from '../../../locations/types';
import { locationStore } from '../../../locations/stores';

type SelectFieldProps = {
  title?: string;
  defaultValue?: string;
  wrapperStyle?: React.CSSProperties;
  options: Location[];
  errorMessage?: string | boolean;
  loading?: boolean;
  required?: boolean;
} & SelectProps;

const LocationSelectField = ({
  title,
  defaultValue,
  required,
  errorMessage,
  options,
  loading,
  wrapperStyle,
  ...props
}: SelectFieldProps) => {
  return (
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage} style={wrapperStyle}>
      {locationStore.location ? (
        <Select
          {...props}
          className={styles.select}
          loading={loading}
          defaultValue={locationStore.location && locationStore.location.name}
        >
          {options.map((option: Location) => (
            <Select.Option key={option.id} value={option.id} title={option.name}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      ) : null}
    </InputFieldTitle>
  );
};

export default observer(LocationSelectField);
