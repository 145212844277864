import { observable, action, runInAction } from 'mobx';

import history from '../../../history';
import { Organization, OrganizationValues } from '../types';
import { organizationsService } from '../services';

class OrganizationStore {
  @observable
  public organization: Organization | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async load(organizationId: number) {
    this.loading = true;

    try {
      const organization = await organizationsService.load(organizationId);
      runInAction(() => {
        this.organization = organization;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async create(formValues: OrganizationValues) {
    this.loading = true;

    try {
      const organization = await organizationsService.create(formValues);
      runInAction(() => {
        this.organization = organization;
      });
      history.push(`/organizations/edit/${organization.id}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async edit(organizationId: number, formValues: OrganizationValues) {
    this.loading = true;

    try {
      await organizationsService.edit(organizationId, formValues);
      const organization = await organizationsService.load((this.organization && this.organization.id) || 0);
      runInAction(() => {
        this.organization = organization;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async delete(organizationId: number) {
    this.loading = true;
    try {
      await organizationsService.delete(organizationId);
      history.push(`/organizations`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action public async updateWithoutRedirect(organizationId: number, otherOrganizationId: number) {
    this.loading = true;
    try {
      await organizationsService.updateWithoutRedirect(organizationId, otherOrganizationId);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new OrganizationStore();
