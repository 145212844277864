import React from 'react';
import { Form, Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import { InputField } from '../../../common/components';

import styles from './editAdministratorForm.module.scss';
import { administratorStore } from '../../stores';
import TwoColumnRow from '../../../common/components/TwoColumnRow';
import { AdministartorValues, Administartor } from '../../types';
import LocationTags from '../LocationTags';

interface EditAdministratorProps {
  administrator: Administartor;
}

const EditAdministratorForm = (props: EditAdministratorProps & FormikProps<AdministartorValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue, administrator } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'ПІБ Адміністратора організації'}
            name="fullName"
            defaultValue={administrator.fullName || values.fullName}
            onBlur={() => setFieldTouched('fullName')}
            errorMessage={touched.fullName && errors.fullName}
            onChange={event => setFieldValue('fullName', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Посада'}
            name="position"
            defaultValue={administrator.position || values.position}
            onBlur={() => setFieldTouched('position')}
            errorMessage={touched.position && errors.position}
            onChange={event => setFieldValue('position', event.target.value)}
          />
        }
      />
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'E-Mail'}
            name="email"
            defaultValue={administrator.email || values.email}
            onBlur={() => setFieldTouched('email')}
            errorMessage={touched.email && errors.email}
            onChange={event => setFieldValue('email', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Номер телефону адміністратора'}
            placeholder="+380"
            name="phoneNumber"
            defaultValue={administrator.phoneNumber || values.phoneNumber}
            onBlur={() => setFieldTouched('phoneNumber')}
            errorMessage={touched.phoneNumber && errors.phoneNumber}
            onChange={event => setFieldValue('phoneNumber', event.target.value)}
          />
        }
      />
      <LocationTags administrator={administrator} />
      <div className={styles.buttonsWrat}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Редагувати
        </Button>
        <Button
          className={styles.button}
          onClick={() => {
            if (administrator.id) {
              administratorStore.delete(administrator.id);
            }
          }}
        >
          Видалити
        </Button>
      </div>
    </Form>
  );
};

const EditAdministratorFormWithFormik = withFormik<EditAdministratorProps, AdministartorValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ administrator }) => ({
    fullName: administrator.fullName || '',
    phoneNumber: administrator.phoneNumber || '',
    position: administrator.position || '',
    email: administrator.email || '',
    password: administrator.password || '',
    role: administrator.role || '',
    status: administrator.status || '',
    organizationId: administrator.organizationId || null
  }),
  handleSubmit: (values, { props: { administrator } }) => {
    if (administrator.id) {
      administratorStore.edit(administrator.id, values);
    }
  },
  validationSchema: yup.object().shape<AdministartorValues>({
    fullName: yup.string().required('Це поле не може бути порожнім!'),
    phoneNumber: yup.string().required('Це поле не може бути порожнім!'),
    position: yup.string().required('Це поле не може бути порожнім!'),
    email: yup.string().required('Це поле не може бути порожнім!')
  }),
  displayName: 'Edit Administrator'
})(observer(EditAdministratorForm));

export default EditAdministratorFormWithFormik;
