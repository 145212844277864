const isProduction = process.env.NODE_ENV === 'production';
const requiredEnvVariables = ['REACT_APP_API_URL', 'REACT_APP_TOUCHCORE_URL'];

const missingEnvs = requiredEnvVariables.filter(env => !process.env[env]);

if (missingEnvs.length) {
  throw Error(`Please set up necessary env variables: ${missingEnvs.join(', ')}`);
}

const settings = {
  appName: 'Super Touchcore',
  domain: process.env.REACT_APP_API_URL,
  touchcoreUrl: process.env.REACT_APP_TOUCHCORE_URL,
  language: 'uk',
  isProduction
};

export default settings;
