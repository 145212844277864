import React from 'react';
import { Form, Button, Divider } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Title, InputField } from '../../../common/components';

import AddMenus from '../AddMenus';
import styles from './createLocationForm.module.scss';
import { LocationValues } from '../../types';
import { menusListStore, locationStore } from '../../stores';
import { organizationStore } from '../../../organizations/stores';
import MenuSelectField from '../MenuSelectField';

const CreateLocationForm = (props: FormikProps<LocationValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <Title title="Дані локації" />
      <InputField
        title={'Назва локації'}
        name="name"
        value={values.name}
        onBlur={() => setFieldTouched('name')}
        errorMessage={touched.name && errors.name}
        onChange={event => setFieldValue('name', event.target.value)}
      />
      <InputField
        title={'Адреса'}
        name="address"
        value={values.address}
        onBlur={() => setFieldTouched('address')}
        errorMessage={touched.address && errors.address}
        onChange={event => setFieldValue('address', event.target.value)}
      />
      <InputField
        title={'ПІБ контактної особи'}
        name="contactFio"
        value={values.contactFio}
        onBlur={() => setFieldTouched('contactFio')}
        errorMessage={touched.contactFio && errors.contactFio}
        onChange={event => setFieldValue('contactFio', event.target.value)}
      />
      <InputField
        title={'Номер телефону контактної особи'}
        placeholder="+380"
        name="contactNumber"
        value={values.contactNumber}
        onBlur={() => setFieldTouched('contactNumber')}
        errorMessage={touched.contactNumber && errors.contactNumber}
        onChange={event => setFieldValue('contactNumber', event.target.value)}
      />
      <MenuSelectField
        options={menusListStore.menus}
        loading={menusListStore.loading}
        title={'Додати меню'}
        onBlur={() => setFieldTouched('menuId')}
        errorMessage={touched.menuId && errors.menuId}
        onChange={value => {
          setFieldValue('menuId', value);
        }}
        wrapperStyle={{ margin: '0px auto', width: '100%' }}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider className={styles.divider} />
            <div className={styles.iconWrap}>
              <div className={styles.icon}>
                <AddMenus props={props} />
              </div>
            </div>
          </div>
        )}
      />
      <div className={styles.buttonsWrat}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Зберегти
        </Button>
        <Link to={`/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`}>
          <Button className={styles.button}>Відміна</Button>
        </Link>
      </div>
    </Form>
  );
};

const CreateLocationFormWithFormik = withFormik<any, LocationValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    menuId: null,
    address: '',
    contactFio: '',
    contactNumber: '',
    name: '',
    organizationId: organizationStore.organization && organizationStore.organization.id
  }),
  handleSubmit: values => {
    locationStore.create(values);
  },
  validationSchema: yup.object().shape<LocationValues>({
    menuId: yup.number(),
    name: yup.string().required('Це поле не може бути порожнім!'),
    contactFio: yup.string().required('Це поле не може бути порожнім!'),
    contactNumber: yup.string().required('Це поле не може бути порожнім!'),
    address: yup.string().required('Це поле не може бути порожнім!')
  }),
  displayName: 'Create location'
})(observer(CreateLocationForm));

export default CreateLocationFormWithFormik;
