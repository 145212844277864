import React from 'react';
import { Icon, Form } from 'antd';
import { withFormik, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import queryString from 'querystring';
import * as yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './locationListFilter.module.scss';
import history from '../../../../history';

import { InputField } from '../../../common/components';
import { clearObjectValues, InputDelay } from '../../../common/utils';
import { organizationStore } from '../../../organizations/stores';

export interface FormProps {
  search: string;
}

const searchByStringDelay = new InputDelay();

const LocationListFilter = (props: FormikProps<FormProps>) => {
  const { handleSubmit, values, setFieldTouched, setFieldValue, touched, errors } = props;
  // TODO: validate if there is not valid division/organization in url
  return (
    <div className={styles.filter}>
      <Form>
        <InputField
          placeholder="Пошук локацій"
          name="search"
          required={false}
          type="text"
          suffix={<Icon type="search" />}
          value={values.search}
          errorMessage={touched.search && errors.search}
          onBlur={() => setFieldTouched('search')}
          onChange={(event: any) => {
            setFieldValue('search', event.target.value);
            searchByStringDelay.onSearch(event.target.value, () => {
              handleSubmit();
            });
          }}
        />
      </Form>
    </div>
  );
};

const LocationListFilterWithFormik = withFormik<RouteComponentProps<{ search: string }>, FormProps>({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const {
      location: { search }
    } = props;
    const filter = queryString.parse(search.substr(1, search.length)) as { [key: string]: string };
    return {
      search: filter && filter.search ? filter.search : ''
    };
  },
  handleSubmit: values => {
    history.push(
      `/organizations/edit/${organizationStore.organization &&
        organizationStore.organization.id}?${queryString.stringify(clearObjectValues(values))}`
    );
  },
  validationSchema: yup.object().shape({
    search: yup.string()
  }),
  displayName: 'Filter'
})(observer(LocationListFilter));

export default withRouter(LocationListFilterWithFormik as any);
