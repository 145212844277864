import * as React from 'react';
import { Helmet } from 'react-helmet';

import logo from './touch.png';

import settings from '../../../../settings';
import { AppLayout } from '../../../common/components';
import styles from './start.module.scss';

const Start = () => {
  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Головна`}</title>
        <meta name="description" content={`${settings.appName} - Головна`} />
      </Helmet>
      <div className={styles.wrap}>
        <div className={styles.logoWrap}>
          <div className={styles.heading}>SUPER TOUCHCORE</div>
          <img className={styles.logo} src={logo} alt="touch logo" />
        </div>
      </div>
    </AppLayout>
  );
};

export default Start;
