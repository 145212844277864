import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';

import styles from './checkboxField.module.scss';
import { InputFieldTitle } from '../index';

type CheckboxFieldProps = {
  label: string;
  errorMessage?: string | boolean;
} & CheckboxProps;

const CheckboxField = ({ label, errorMessage, ...props }: CheckboxFieldProps) => {
  return (
    <InputFieldTitle title="" required={false} errorMessage={errorMessage}>
      <Checkbox {...props}>
        <span className={styles.checkBoxText}>{label}</span>
      </Checkbox>
    </InputFieldTitle>
  );
};

export default CheckboxField;
