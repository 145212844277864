import React from 'react';
import { Form, DatePicker, Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Title, InputField } from '../../../common/components';

import styles from './editOrganizationForm.module.scss';
import { OrganizationValues } from '../../types';
import TwoColumnRow from '../../../common/components/TwoColumnRow';
import { organizationStore } from '../../stores';

const EditOrganizationForm = (props: FormikProps<OrganizationValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <Title title="Дані організації" />
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'Назва організації'}
            name="name"
            defaultValue={organizationStore.organization ? organizationStore.organization.name : values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Код ЄДРПОУ юр.особи або ФОП'}
            name="edrpou"
            defaultValue={organizationStore.organization ? organizationStore.organization.edrpou : values.edrpou}
            onBlur={() => setFieldTouched('edrpou')}
            errorMessage={touched.edrpou && errors.edrpou}
            onChange={event => setFieldValue('edrpou', event.target.value)}
          />
        }
      />
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'Адреса'}
            name="address"
            defaultValue={organizationStore.organization ? organizationStore.organization.address : values.address}
            onBlur={() => setFieldTouched('address')}
            errorMessage={touched.address && errors.address}
            onChange={event => setFieldValue('address', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Номер договору'}
            name="contractNumber"
            defaultValue={
              organizationStore.organization ? organizationStore.organization.contractNumber : values.contractNumber
            }
            onBlur={() => setFieldTouched('contractNumber')}
            errorMessage={touched.contractNumber && errors.contractNumber}
            onChange={event => setFieldValue('contractNumber', event.target.value)}
          />
        }
      />
      <TwoColumnRow
        firstColumn={
          <div className={styles.dateFields}>
            <div className={styles.leftDataPicker}>
              <div>Дата складання договору:</div>
              <DatePicker
                placeholder={
                  organizationStore.organization
                    ? `${new Date(Number(organizationStore.organization.contractDateStart)).toLocaleDateString()}`
                    : 'Дата складання'
                }
                format={'YYYY/MM/DD'}
                onOpenChange={() => setFieldTouched('contractDateStart')}
                onChange={(_, dateString) =>
                  setFieldValue(
                    'contractDateStart',
                    organizationStore.organization
                      ? Number(organizationStore.organization.contractDateStart)
                      : dateString
                  )
                }
              />
            </div>
            <div className={styles.rightDataPicker}>
              <div>Термін дії договору до:</div>
              <DatePicker
                placeholder={
                  organizationStore.organization
                    ? `${new Date(Number(organizationStore.organization.contractDateEnd)).toLocaleDateString()}`
                    : 'Термін дії до'
                }
                format={'YYYY/MM/DD'}
                onOpenChange={() => setFieldTouched('contractDateEnd')}
                onChange={(_, dateString) =>
                  setFieldValue(
                    'contractDateEnd',
                    organizationStore.organization ? Number(organizationStore.organization.contractDateEnd) : dateString
                  )
                }
              />
            </div>
          </div>
        }
        secondColumn={
          <div className={styles.autoprolong}>
            <div>Наявність автопролонгації договору:</div>
            <div className={styles.autoprolongRadio}>
              <label className={styles.label}>
                <input
                  type="radio"
                  name="autoProlong"
                  checked={values.autoProlong === true}
                  onChange={() => setFieldValue('autoProlong', true)}
                />
                Так
              </label>
              <label className={styles.label}>
                <input
                  type="radio"
                  name="autoProlong"
                  checked={values.autoProlong === false}
                  onChange={() => setFieldValue('autoProlong', false)}
                />
                Ні
              </label>
            </div>
          </div>
        }
      />
      <Title title="Дані керівника" />
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'ПІБ керівника'}
            name="chiefFio"
            defaultValue={organizationStore.organization ? organizationStore.organization.chiefFio : values.chiefFio}
            onBlur={() => setFieldTouched('chiefFio')}
            errorMessage={touched.chiefFio && errors.chiefFio}
            onChange={event => setFieldValue('chiefFio', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Номер телефону керівника'}
            placeholder="+380"
            name="chiefNumber"
            defaultValue={
              organizationStore.organization ? organizationStore.organization.chiefNumber : values.chiefNumber
            }
            onBlur={() => setFieldTouched('chiefNumber')}
            errorMessage={touched.chiefNumber && errors.chiefNumber}
            onChange={event => setFieldValue('chiefNumber', event.target.value)}
          />
        }
      />
      <Title title="Додаткові дані" />
      <TwoColumnRow
        firstColumn={
          <div className={styles.advertising}>
            <div>Є картки лояльності</div>
            <div className={styles.advertisingRadio}>
              <label className={styles.label}>
                <input
                  type="radio"
                  name="loyalty"
                  checked={values.loyalty === true}
                  onChange={() => setFieldValue('loyalty', true)}
                />
                Так
              </label>
              <label className={styles.label}>
                <input
                  type="radio"
                  name="loyalty"
                  checked={values.loyalty === false}
                  onChange={() => setFieldValue('loyalty', false)}
                />
                Ні
              </label>
            </div>
          </div>
        }
        secondColumn={
          values.loyalty ? (
            <>
              <InputField
                title={'Посилання системи лояльності (Url)'}
                placeholder=""
                name="loyaltyUrl"
                required={false}
                defaultValue={
                  organizationStore.organization ? organizationStore.organization.loyaltyUrl : values.loyaltyUrl
                }
                onBlur={() => setFieldTouched('loyaltyUrl')}
                errorMessage={touched.loyaltyUrl && errors.loyaltyUrl}
                onChange={event => setFieldValue('loyaltyUrl', event.target.value)}
              />
              <InputField
                title={'Ключ системи лояльності'}
                placeholder=""
                required={false}
                name="loyaltyKey"
                defaultValue={
                  organizationStore.organization ? organizationStore.organization.loyaltyKey : values.loyaltyKey
                }
                onBlur={() => setFieldTouched('loyaltyKey')}
                errorMessage={touched.loyaltyKey && errors.loyaltyKey}
                onChange={event => setFieldValue('loyaltyKey', event.target.value)}
              />
            </>
          ) : (
            ''
          )
        }
      />
      <TwoColumnRow
        firstColumn={
          <div className={styles.advertising}>
            <div>Надати дозвіл для реклами?:</div>
            <div className={styles.advertisingRadio}>
              <label className={styles.label}>
                <input
                  type="radio"
                  name="advertising"
                  checked={values.advertising === true}
                  onChange={() => setFieldValue('advertising', true)}
                />
                Так
              </label>
              <label className={styles.label}>
                <input
                  type="radio"
                  name="advertising"
                  checked={values.advertising === false}
                  onChange={() => setFieldValue('advertising', false)}
                />
                Ні
              </label>
            </div>
          </div>
        }
        secondColumn={''}
      />
      <Title title="Дані касової системи" />
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'Логін'}
            name="oneCLogin"
            defaultValue={organizationStore.organization ? organizationStore.organization.oneCLogin : values.oneCLogin}
            onBlur={() => setFieldTouched('oneCLogin')}
            errorMessage={touched.oneCLogin && errors.oneCLogin}
            onChange={event => setFieldValue('oneCLogin', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Пароль'}
            name="oneCPass"
            defaultValue={organizationStore.organization ? organizationStore.organization.oneCPass : values.oneCPass}
            onBlur={() => setFieldTouched('oneCPass')}
            errorMessage={touched.oneCPass && errors.oneCPass}
            onChange={event => setFieldValue('oneCPass', event.target.value)}
          />
        }
      />
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'Посилання на сервер'}
            name="oneCUrl"
            defaultValue={organizationStore.organization ? organizationStore.organization.oneCUrl : values.oneCUrl}
            onBlur={() => setFieldTouched('oneCUrl')}
            errorMessage={touched.oneCUrl && errors.oneCUrl}
            onChange={event => setFieldValue('oneCUrl', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Тип платежу (ID)'}
            name="oneCPaymentType"
            defaultValue={
              organizationStore.organization ? organizationStore.organization.oneCPaymentType : values.oneCPaymentType
            }
            onBlur={() => setFieldTouched('oneCPaymentType')}
            errorMessage={touched.oneCPaymentType && errors.oneCPaymentType}
            onChange={event => setFieldValue('oneCPaymentType', event.target.value)}
          />
        }
      />

      <div className={styles.buttonsWrat}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Редагувати
        </Button>
        <Button
          className={styles.button}
          onClick={() => {
            if (organizationStore.organization) {
              organizationStore.delete(organizationStore.organization.id);
            }
          }}
        >
          Видалити
        </Button>
        {organizationStore.organization && organizationStore.organization.users ? (
          <Link to="/administrators/create">
            <Button className={styles.button}>Додати нового адміністратора</Button>
          </Link>
        ) : null}
        {organizationStore.organization ? (
          <Link to="/organizations/refresh-locations">
            <Button className={styles.button}>Оновити список локацій</Button>
          </Link>
        ) : null}
      </div>
    </Form>
  );
};

const EditOrganizationFormWithFormik = withFormik<any, OrganizationValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    address: (organizationStore.organization && organizationStore.organization.address) || '',
    autoProlong: (organizationStore.organization && organizationStore.organization.autoProlong) || false,
    advertising: (organizationStore.organization && organizationStore.organization.advertising) || false,
    chiefFio: (organizationStore.organization && organizationStore.organization.chiefFio) || '',
    chiefNumber: (organizationStore.organization && organizationStore.organization.chiefNumber) || '',
    contractDateEnd: (organizationStore.organization && organizationStore.organization.contractDateEnd) || null,
    contractDateStart: (organizationStore.organization && organizationStore.organization.contractDateStart) || null,
    contractNumber: (organizationStore.organization && organizationStore.organization.contractNumber) || '',
    edrpou: (organizationStore.organization && organizationStore.organization.edrpou) || '',
    name: (organizationStore.organization && organizationStore.organization.name) || '',
    oneCLogin: (organizationStore.organization && organizationStore.organization.oneCLogin) || '',
    oneCPass: (organizationStore.organization && organizationStore.organization.oneCPass) || '',
    oneCUrl: (organizationStore.organization && organizationStore.organization.oneCUrl) || '',
    oneCPaymentType: (organizationStore.organization && organizationStore.organization.oneCPaymentType) || '',
    loyalty: (organizationStore.organization && organizationStore.organization.loyalty) || false,
    loyaltyUrl: (organizationStore.organization && organizationStore.organization.loyaltyUrl) || '',
    loyaltyKey: (organizationStore.organization && organizationStore.organization.loyaltyKey) || ''
  }),
  handleSubmit: values => {
    if (organizationStore.organization) {
      organizationStore.edit(organizationStore.organization.id, values);
    }
  },
  validationSchema: yup.object().shape<OrganizationValues>({
    name: yup.string().required('Це поле не може бути порожнім!'),
    edrpou: yup.string().required('Це поле не може бути порожнім!'),
    address: yup.string().required('Це поле не може бути порожнім!'),
    contractNumber: yup.string().required('Це поле не може бути порожнім!'),
    contractDateEnd: yup.number().required('Це поле не може бути порожнім!'),
    contractDateStart: yup.number().required('Це поле не може бути порожнім!'),
    autoProlong: yup.boolean().required('Це поле не може бути порожнім!'),
    advertising: yup.boolean().required('Це поле не може бути порожнім!'),
    chiefFio: yup.string().required('Це поле не може бути порожнім!'),
    chiefNumber: yup.string().required('Це поле не може бути порожнім!'),
    oneCLogin: yup.string(),
    oneCPass: yup.string(),
    oneCUrl: yup.string(),
    oneCPaymentType: yup.string(),
    loyalty: yup.boolean().required('Це поле не може бути порожнім!'),
    loyaltyUrl: yup.string(),
    loyaltyKey: yup.string()
  }),
  displayName: 'Edit Organization'
})(observer(EditOrganizationForm));

export default EditOrganizationFormWithFormik;
