import axios from 'axios';
import { notification } from 'antd';

import settings from '../../../settings';

class AdvertisingsService {
  public download = async (storagePath: string) => {
    try {
      const { data } = await axios.post(
        `${settings.domain}/api/admin/image/storage/download?storagePath=${storagePath || ''}`
      );
      return data;
    } catch (error) {
      return { code: 0, message: null };
    }
  };

  public upload = async (file: FormData, contentType: string, deviceId: number) => {
    try {
      const {
        data
      } = await axios.post(
        `${settings.domain}/api/admin/image/storage/upload-device-advertising/${contentType}/${deviceId}`,
        file,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка.',
        description: 'Не вдалося завантажити файл. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new AdvertisingsService();
