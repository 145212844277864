import React, { useEffect } from 'react';
import { Form, Button, Divider } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import AddMenus from '../../../locations/components/AddMenus';
import styles from './refreshLocationsForm.module.scss';
import { menusListStore } from '../../../locations/stores';
import MenuSelectField from '../../../locations/components/MenuSelectField';
import { refreshLocationsStore, organizationStore } from '../../stores';

const RefreshLocationsForm = (props: FormikProps<any>) => {
  useEffect(() => {
    menusListStore.loadList();
  }, []);
  const { handleSubmit, setFieldTouched, setFieldValue } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <MenuSelectField
        options={menusListStore.menus}
        loading={menusListStore.loading}
        title={'Додати меню'}
        onBlur={() => setFieldTouched('menuId')}
        onChange={value => {
          setFieldValue('menuId', value);
        }}
        wrapperStyle={{ margin: '0px auto', width: '100%' }}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider className={styles.divider} />
            <div className={styles.iconWrap}>
              <div className={styles.icon}>
                <AddMenus props={props} />
              </div>
            </div>
          </div>
        )}
      />
      <div className={styles.buttonsWrat}>
        <Button type="primary" loading={refreshLocationsStore.loading} htmlType="submit" className={styles.button}>
          Оновити список локацій
        </Button>
      </div>
    </Form>
  );
};

const RefreshLocationsFormWithFormik = withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    menuId: null
  }),
  handleSubmit: values => {
    if (organizationStore.organization) {
      refreshLocationsStore.refresh({
        organizationId: organizationStore.organization.id,
        menuId: values.menuId
      });
    }
  },
  validationSchema: yup.object().shape<any>({
    menuId: yup.number()
  }),
  displayName: 'Refresh locations'
})(observer(RefreshLocationsForm));

export default RefreshLocationsFormWithFormik;
