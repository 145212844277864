import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Menu as MenuAntd } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import EditLocationForm from '../../components/EditLocationForm';
import settings from '../../../../settings';
import { AppLayout, Breadcrumbs, Title } from '../../../common/components';
import styles from './edit.module.scss';
import { locationStore, menusListStore } from '../../stores';
import { organizationStore } from '../../../organizations/stores';
import KiosksList from '../../../devices/components/DevicesList';
import { deviceStore } from '../../../devices/stores';

const { SubMenu } = MenuAntd;

const Edit = ({ match }: RouteComponentProps<{ id: string }>) => {
  useEffect(() => {
    locationStore.load(Number(match.params.id));
    menusListStore.loadList();
    deviceStore.device = null;
  }, [match.params.id]);

  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Локація`}</title>
        <meta name="description" content={`${settings.appName} - Локація`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' },
            {
              name: `${organizationStore.organization && organizationStore.organization.name}`,
              url: `/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`
            },
            {
              name: `${locationStore.location && locationStore.location.name}`,
              url: `/locations/edit/${locationStore.location && locationStore.location.id}`
            }
          ]}
        />
        <div className={styles.info}>
          <div className={styles.titleWrap}>
            <Title
              title={`${locationStore.location && locationStore.location.name} ${locationStore.location &&
                locationStore.location.address}`}
            />
            <div>{`Мережа - ${locationStore.location && locationStore.location.name}`}</div>
          </div>
          <MenuAntd defaultSelectedKeys={['0']} defaultOpenKeys={['sub0']} mode="inline">
            <SubMenu className={styles.subMenu} key="sub1" title={<Title title="Контактна інформація" />}>
              <div className={styles.subMenuContent}>
                <EditLocationForm />
              </div>
            </SubMenu>
          </MenuAntd>
          <KiosksList />
        </div>
      </div>
    </AppLayout>
  );
};

export default observer(Edit);
