import * as React from 'react';
import { configure } from 'mobx';
import { Router, BrowserRouter, Switch } from 'react-router-dom';

import modules from './modules';
import history from './history';

configure({ enforceActions: 'observed' });

const isBrowserSupportsHistory = 'pushState' in window.history;

class App extends React.Component {
  public render() {
    return modules.getDataRoot(
      <BrowserRouter forceRefresh={!isBrowserSupportsHistory}>
        <Router history={history}>
          <Switch>{modules.routes}</Switch>
        </Router>
      </BrowserRouter>
    );
  }
}

export default App;
