import React, { useEffect, useState } from 'react';
import { Tag, Spin } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import { observer } from 'mobx-react-lite';

import { Location } from '../../../locations/types';

import { Administartor } from '../../types';
import SelectField from '../SelectField';
import styles from './locationTags.module.scss';
import { organizationStore } from '../../../organizations/stores';
import { administratorStore } from '../../stores';

interface LocationTagProps {
  administrator: Administartor;
}

const LocationTags = ({ administrator }: LocationTagProps) => {
  useEffect(() => {
    administratorStore.load(administrator.id);
  }, [administrator.id]);

  const forMap = (tag: Location) => {
    const tagElem = (
      <Tag closable={true} onClose={() => administratorStore.removeLocations(administrator.id, tag.id)}>
        {tag.name}
      </Tag>
    );
    return (
      <span key={tag.id} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = administrator.locations ? administrator.locations.map((tag: Location) => forMap(tag)) : null;

  let locations = organizationStore.organization &&  organizationStore.organization.locations &&
      organizationStore.organization.locations;

  const filterOptions = (input: string, option: any) => {
    let value = input.trim().toLowerCase();
    const optionValue = option.props.children.props.children.toLowerCase();
    return optionValue.indexOf(value) >= 0 ? true : false;
  } 

  const [selectedCity, setSelectedCity] = useState('Всі міста');

  const cities = [{name: 'Всі міста', id:'Всі міста'}];
  locations && locations.map(location=>{
    if(location.address && !cities.find(city=>city.name === location.address.toString())){
      cities.push({name: location.address.toString(), id: location.address.toString()})
    }
  });

  return !administratorStore.loading ? (
    <div className={styles.wrap}>
      <div className={styles.tagsView}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
      <div className={styles.selectWrap}>
      <SelectField
          defaultValue={selectedCity}
          required={false}
          loading={organizationStore.loading}
          options={cities}
          onChange={(option) => setSelectedCity(option.toString())}
          wrapperStyle={{ margin: '16px auto 0 auto', width: '98%' }}
          showSearch
          isCity={true}
        />
        <SelectField
          defaultValue={'Додати нову локацію до адміністратора'}
          required={false}
          loading={organizationStore.loading}
          options={
            locations ? (selectedCity === 'Всі міста' ? 
              locations : 
                locations.filter(location => location.address === selectedCity)) : []
          }
          onChange={locationId => {
            administratorStore.addLocations(administrator.id, Number(locationId));
          }}
          wrapperStyle={{ margin: '16px auto 0 auto', width: '98%' }}
          showSearch
          filterOption={filterOptions}
        />
      </div>
    </div>
  ) : (
    <div className={styles.spinWrap}>
      <Spin />
    </div>
  );
};

export default observer(LocationTags);
