import React from 'react';
import moment from 'moment';
import { Form, Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Title, InputField, InputPassword } from '../../../common/components';

import styles from './createDeviceForm.module.scss';
import { locationStore } from '../../../locations/stores';
import { DeviceValues } from '../../types';
import { deviceStore } from '../../stores';
import TimePickerField from '../../../common/components/TimePickerField';
import { setFieldTouchedAsync } from '../../../common/utils';
import CheckboxField from '../../../common/components/CheckboxField';

const CreateDeviceForm = (props: FormikProps<DeviceValues>) => {
    const {
        handleSubmit,
        values,
        setFieldTouched,
        touched,
        errors,
        setFieldValue,
    } = props;
    return (
        <Form className={styles.form} onSubmit={handleSubmit}>
            <Title title="Новий кіоск" />
            <InputField
                title={'Базове посилання на сервер клієнта'}
                name="baseUrl"
                value={values.baseUrl}
                onBlur={() => setFieldTouched('baseUrl')}
                errorMessage={touched.baseUrl && errors.baseUrl}
                onChange={(event) =>
                    setFieldValue('baseUrl', event.target.value)
                }
            />
            <InputField
                title={"Ім'я авторизації"}
                name="username"
                value={values.username}
                onBlur={() => setFieldTouched('username')}
                errorMessage={touched.username && errors.username}
                onChange={(event) =>
                    setFieldValue('username', event.target.value)
                }
            />
            <InputPassword
                title={'Пароль авторизації'}
                name="password"
                value={values.password}
                onBlur={() => setFieldTouched('password')}
                errorMessage={touched.password && errors.password}
                onChange={(event) =>
                    setFieldValue('password', event.target.value)
                }
            />
            <InputField
                title={'Ідентифікатор меню'}
                name="settingId"
                value={values.settingId}
                onBlur={() => setFieldTouched('settingId')}
                errorMessage={touched.settingId && errors.settingId}
                onChange={(event) =>
                    setFieldValue('settingId', event.target.value)
                }
            />

            <div className={styles.checkboxWrap}>
                <CheckboxField
                    checked={/true/i.test(values.virtual)}
                    onChange={(e) => setFieldValue('virtual', e.target.checked)}
                    label="Віртуальний кіоск"
                />

                <InputField
                    title={'Рахунок мерчанта'}
                    disabled={!values.virtual}
                    required={false}
                    name="merchantAccount"
                    value={
                        (deviceStore.device &&
                            deviceStore.device.properties.merchantAccount) ||
                        values.merchantAccount
                    }
                    onBlur={() => setFieldTouched('merchantAccount')}
                    errorMessage={
                        touched.merchantAccount && errors.merchantAccount
                    }
                    onChange={(event) =>
                        setFieldValue('merchantAccount', event.target.value)
                    }
                />

                <InputPassword
                    title={'Секретний ключ мерчанта'}
                    disabled={!values.virtual}
                    name="password"
                    required={false}
                    value={
                        (deviceStore.device &&
                            deviceStore.device.properties.merchantSecretKey) ||
                        values.merchantSecretKey
                    }
                    onBlur={() => setFieldTouched('merchantSecretKey')}
                    errorMessage={
                        touched.merchantSecretKey && errors.merchantSecretKey
                    }
                    onChange={(event) =>
                        setFieldValue('merchantSecretKey', event.target.value)
                    }
                />
                <div className={styles.timePickerWrap}>
                    <TimePickerField
                        title={`Початок роботи`}
                        disabled={!values.virtual}
                        className={styles.timePicker}
                        required={false}
                        placeholder=""
                        errorMessage={
                            touched.startTime && (errors.startTime as string)
                        }
                        onOpenChange={(status) =>
                            !status &&
                            setFieldTouchedAsync(() =>
                                setFieldTouched('startTime')
                            )
                        }
                        value={
                            values.startTime
                                ? moment(values.startTime)
                                : undefined
                        }
                        onChange={(date) =>
                            setFieldValue(
                                'startTime',
                                date ? moment(date) : null
                            )
                        }
                    />
                    <TimePickerField
                        placeholder=""
                        disabled={!values.virtual}
                        title={'Кінець роботи'}
                        className={styles.timePicker}
                        required={false}
                        errorMessage={
                            touched.endTime && (errors.endTime as string)
                        }
                        onOpenChange={(status) =>
                            !status &&
                            setFieldTouchedAsync(() =>
                                setFieldTouched('endTime')
                            )
                        }
                        value={
                            values.endTime ? moment(values.endTime) : undefined
                        }
                        onChange={(date) =>
                            setFieldValue('endTime', date ? moment(date) : null)
                        }
                    />
                </div>
                <div className={styles.timePickerWrap}>
                    <InputField
                        title={'Кількість столиків'}
                        name="table"
                        value={
                            (deviceStore.device &&
                                deviceStore.device.properties.table) ||
                            values.table
                        }
                        onBlur={() => setFieldTouched('table')}
                        onChange={(event) =>
                            setFieldValue('table', event.target.value)
                        }
                    />
                    <CheckboxField
                        checked={/true/i.test(values.delivery)}
                        onChange={(e) =>
                            setFieldValue('delivery', e.target.checked)
                        }
                        label="Є доставка"
                    />
                </div>
            </div>

            <div className={styles.buttonsWrat}>
                <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.button}
                >
                    Зберегти
                </Button>
                <Link
                    to={`/locations/edit/${locationStore.location &&
                        locationStore.location.id}`}
                >
                    <Button className={styles.button}>Відміна</Button>
                </Link>
            </div>
        </Form>
    );
};

const CreateDeviceFormWithFormik = withFormik<any, DeviceValues>({
    enableReinitialize: true,
    mapPropsToValues: () => ({
        baseUrl: '',
        username: '',
        password: '',
        settingId: '',
        merchantAccount: '',
        merchantSecretKey: '',
        startTime: '',
        endTime: '',
        virtual: 'false',
        delivery: 'false',
        table: 0,
    }),
    handleSubmit: (values) => {
        if (locationStore.location) {
            deviceStore.create(locationStore.location.id, values);
        }
    },
    validationSchema: yup.object().shape<DeviceValues>({
        baseUrl: yup.string().required('Це поле не може бути порожнім!'),
        username: yup.string().required('Це поле не може бути порожнім!'),
        password: yup.string().required('Це поле не може бути порожнім!'),
        settingId: yup.string().required('Це поле не може бути порожнім!'),
        merchantAccount: yup.string().when('virtual', {
            is: true,
            then: yup.string().required('Це поле не може бути порожнім!'),
        }),
        merchantSecretKey: yup.string().when('virtual', {
            is: true,
            then: yup.string().required('Це поле не може бути порожнім!'),
        }),
        startTime: yup.string(),
        endTime: yup.string(),
        virtual: yup.string(),
        delivery: yup.string(),
        table: yup.number(),
    }),
    displayName: 'Create device',
})(observer(CreateDeviceForm));

export default CreateDeviceFormWithFormik;
