import React, { useState } from 'react';
import Button from 'antd/lib/button/button';
import { Modal } from 'antd';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

import { menuStore } from '../../stores';

import { InputField } from '../../../common/components';
import './addMenus.global.scss';
import styles from './addMenus.module.scss';
import { MenuValues } from '../../types';

const AddMenus = (props: any) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const handleOk = () => {
    handleSubmit();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  return (
    <div>
      <Button onMouseDown={showModal}>Додати нове меню</Button>
      <Modal className="modalWrap" title="Додати нове меню" visible={visible} onOk={handleOk} onCancel={handleCancel}>
        <form className={styles.form}>
          <InputField
            title={'Назва меню'}
            placeholder={'Мінімум 2, максимум 40 символів'}
            name="name"
            value={values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />
        </form>
      </Modal>
    </div>
  );
};

const AddMenusWithFormik = withFormik<any, MenuValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: ''
  }),
  handleSubmit: (values, { resetForm }) => {
    menuStore.create(values);
    resetForm();
  },
  validationSchema: yup.object().shape<MenuValues>({
    name: yup
      .string()
      .min(2, 'Назва повинна мати не меньше 2 символів')
      .max(40, 'Назва повинна мати не більше 40 символів')
      .required('Це поле не може бути порожнім!')
  }),
  displayName: 'Add menus'
})(observer(AddMenus));

export default AddMenusWithFormik;
