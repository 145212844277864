import { observable, action, runInAction } from 'mobx';

import { refreshLocationsService } from '../services';

interface RefreshLocationsProps {
  organizationId: number;
  menuId: number;
}

class RefreshLocations {
  @observable
  public loading = false;
  @observable
  public error: Error | null = null;

  @action
  public refresh = async (values: RefreshLocationsProps) => {
    this.loading = true;

    try {
      const data = await refreshLocationsService.refresh(values);
      if (data.code === 200) {
        runInAction(() => {
          this.loading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };
}

export default new RefreshLocations();
