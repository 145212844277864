import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import styles from './selectField.module.scss';
import { InputFieldTitle } from '../../../common/components';

type SelectFieldProps = {
  title?: string;
  defaultValue?: string;
  wrapperStyle?: React.CSSProperties;
  options: Array<{ id?: string | number; name: string; description?: string; address?:string }>;
  errorMessage?: string | boolean;
  loading?: boolean;
  required?: boolean;
  isCity?:boolean;
} & SelectProps;

const SelectField = ({
  title,
  defaultValue,
  required,
  errorMessage,
  options,
  loading,
  wrapperStyle,
  ...props
}: SelectFieldProps) => {
  return (
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage} style={wrapperStyle}>
      <Select {...props} className={styles.select} loading={loading} value={props.isCity ? defaultValue : ' +  додати локацію до адміністратора'}>
        {options.map(option => (
          <Select.Option key={option.id} value={option.id} className={styles.option}>
            <div className={styles.optionName}>{option.name+ (option.address ? ' ('+option.address+')': '')}</div>
          </Select.Option>
        ))}
      </Select>
    </InputFieldTitle>
  );
};

export default SelectField;
