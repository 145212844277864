import React from 'react';
import { Select, Button, Icon } from 'antd';
import { SelectProps } from 'antd/lib/select';

import { observer } from 'mobx-react-lite';

import styles from './menuSelectField.module.scss';
import { InputFieldTitle } from '../../../common/components';
import { menuStore, showModalEditMenuStore } from '../../stores';
import EditMenu from '../EditMenu';
import { Menu } from '../../types';

type SelectFieldProps = {
  title?: string;
  defaultValue?: string;
  wrapperStyle?: React.CSSProperties;
  options: Menu[] | null;
  errorMessage?: string | boolean;
  loading?: boolean;
  required?: boolean;
} & SelectProps;

const MenuSelectField = ({
  title,
  defaultValue,
  required,
  errorMessage,
  options,
  loading,
  wrapperStyle,
  ...props
}: SelectFieldProps) => {
  return (
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage} style={wrapperStyle}>
      {!showModalEditMenuStore.loading && showModalEditMenuStore.menu ? (
        <EditMenu menu={showModalEditMenuStore.menu} />
      ) : null}
      <Select
        {...props}
        className={styles.select}
        loading={loading}
        defaultValue={(menuStore.menu && menuStore.menu.name) || ''}
      >
        {options &&
          options.map((option: Menu) => (
            <Select.Option
              key={option.name + option.id}
              value={option.id}
              title={option.description}
              className={styles.option}
            >
              <div>{option.name}</div>
              <div className={styles.buttonsWrap}>
                <Button
                  size="small"
                  className={styles.button}
                  onClick={event => {
                    event.stopPropagation();
                    showModalEditMenuStore.isShow(true, option);
                  }}
                >
                  <Icon type="edit" />
                </Button>
                <Button
                  size="small"
                  className={styles.button}
                  onClick={event => {
                    event.stopPropagation();
                    menuStore.delete(Number(option.id));
                  }}
                >
                  <Icon type="delete" />
                </Button>
              </div>
            </Select.Option>
          ))}
      </Select>
    </InputFieldTitle>
  );
};

export default observer(MenuSelectField);
