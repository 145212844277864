import { observable, action, runInAction } from 'mobx';

import settings from '../../../settings';
import history from '../../../history';
import { Administartor, AdministartorValues, ConfirmValues } from '../types';
import { administratorsService } from '../services';
import { organizationStore } from '../../organizations/stores';

class AdministratorStore {
  @observable
  public administrator: Administartor | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async load(administratorId: number) {
    this.loading = true;

    try {
      const administrator = await administratorsService.load(administratorId);
      runInAction(() => {
        this.administrator = administrator;
      });
      organizationStore.load((this.administrator && this.administrator.organizationId) || 0);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async create(formValues: AdministartorValues) {
    this.loading = true;

    try {
      const administrator = await administratorsService.create(formValues);
      runInAction(() => {
        this.administrator = administrator;
      });
      history.push(`/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async confirm(formValues: ConfirmValues) {
    this.loading = true;

    try {
      const administrator = await administratorsService.confirm(formValues);
      runInAction(() => {
        this.administrator = administrator;
      });
      window.location.replace(`${settings.touchcoreUrl}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async edit(administratorId: number, formValues: AdministartorValues) {
    this.loading = true;

    try {
      const administrator = await administratorsService.edit(administratorId, formValues);
      runInAction(() => {
        this.administrator = administrator;
      });
      organizationStore.load((organizationStore.organization && organizationStore.organization.id) || 0);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async delete(administratorId: number) {
    this.loading = true;

    try {
      await administratorsService.delete(administratorId);
      organizationStore.load((organizationStore.organization && organizationStore.organization.id) || 0);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async addLocations(administratorId: number, locationId: number) {
    this.loading = true;

    const locationIds: number[] = [];
    // tslint:disable-next-line: no-unused-expression
    organizationStore.organization &&
      organizationStore.organization.users &&
      organizationStore.organization.users.forEach(
        user =>
          user &&
          user.id === administratorId &&
          user.locations &&
          user.locations.forEach(location => locationIds.push(location.id))
      );

    // tslint:disable-next-line: no-unused-expression
    !locationIds.includes(locationId) && locationIds.push(locationId);

    try {
      await administratorsService.addLocation(administratorId, locationIds);
      organizationStore.load((organizationStore.organization && organizationStore.organization.id) || 0);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async removeLocations(administratorId: number, locationId: number) {
    this.loading = true;

    const locationIds: number[] = [];
    // tslint:disable-next-line: no-unused-expression
    organizationStore.organization &&
      organizationStore.organization.users &&
      organizationStore.organization.users.forEach(
        user =>
          user &&
          user.id === administratorId &&
          user.locations &&
          user.locations.forEach(location => locationIds.push(location.id))
      );

    // tslint:disable-next-line: no-unused-expression
    const idx = locationIds.indexOf(locationId);
    locationIds.splice(idx, 1);

    try {
      await administratorsService.addLocation(administratorId, locationIds);
      organizationStore.load((organizationStore.organization && organizationStore.organization.id) || 0);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new AdministratorStore();
