import * as React from 'react';
import { Route } from 'react-router-dom';

import Create from './pages/Create';
import Confirm from './pages/Confirm';
import Module from '../Connector';

export default new Module({
  route: [
    <Route exact={true} path="/administrators/create" component={Create} />,
    <Route exact={true} path="/administrators/confirm/:id" component={Confirm} />
  ]
});
