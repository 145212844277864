import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import queryString from 'querystring';

import { Button, Table, Icon } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

import styles from './organizationList.module.scss';
import OrganizationListFilter from '../../components/OrganizationListFilter';
import { organizationListStore } from '../../stores';
import { Title } from '../../../common/components';
import { Organization } from '../../types';

const OrganizationList = () => {
    const currentLocation = useLocation();
    const filter = queryString.parse(
        currentLocation.search.substr(1, currentLocation.search.length)
    );

    const columns = [
        {
            title: 'Організація',
            align: 'center' as 'center',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Кількість локацій',
            align: 'center' as 'center',
            dataIndex: 'locations',
            key: 'locations',
        },
        {
            title: 'Кількість кіосків',
            align: 'center' as 'center',
            dataIndex: 'devices',
            key: 'devices',
        },
        {
            title: 'Порядок відображення',
            align: 'center' as 'center',
            key: 'ordering',
            dataIndex: 'ordering',
        },
        {
            title: '',
            align: 'center' as 'center',
            dataIndex: '',
            key: '',
        },
        {
            title: '',
            align: 'center' as 'center',
            key: 'action',
            dataIndex: 'action',
        },
    ];

    const getDataSource = () => {
        const tableRows: any = [];
        if (organizationListStore.organizations) {
            organizationListStore.organizations.forEach(
                ({ id, name, locations, devices, order }: Organization) => {
                    if ( name.toLowerCase().indexOf(String(filter.search).toLowerCase()) >= 0) {
                        tableRows.push({
                            key: id,
                            name,
                            locations: (locations && locations.length) || 0,
                            devices: (devices && devices.length) || 0,
                            ordering: (
                                <ButtonGroup size="small">
                                    <Button
                                        onClick={() =>
                                            organizationListStore.changeOrganizationOrder(
                                                'UP',
                                                id,
                                                order
                                            )
                                        }
                                    >
                                        <Icon type="caret-up" />
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            organizationListStore.changeOrganizationOrder(
                                                'DOWN',
                                                id,
                                                order
                                            )
                                        }
                                    >
                                        <Icon type="caret-down" />
                                    </Button>
                                </ButtonGroup>
                            ),
                            action: (
                                <Link to={`/organizations/edit/${id}`}>
                                    <Button type="primary">Перейти</Button>
                                </Link>
                            ),
                        });
                    } else if (filter.search === undefined) {
                        tableRows.push({
                            key: id,
                            name,
                            locations: (locations && locations.length) || 0,
                            devices: (devices && devices.length) || 0,
                            ordering: (
                                <ButtonGroup size="small">
                                    <Button
                                        onClick={() =>
                                            organizationListStore.changeOrganizationOrder(
                                                'UP',
                                                id,
                                                order
                                            )
                                        }
                                    >
                                        <Icon type="caret-up" />
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            organizationListStore.changeOrganizationOrder(
                                                'DOWN',
                                                id,
                                                order
                                            )
                                        }
                                    >
                                        <Icon type="caret-down" />
                                    </Button>
                                </ButtonGroup>
                            ),
                            action: (
                                <Link to={`/organizations/edit/${id}`}>
                                    <Button type="primary">Перейти</Button>
                                </Link>
                            ),
                        });
                    }
                }
            );
        }
        return tableRows;
    };

    const getCountOflocations = () => {
        const allLocations = [];
        const locations =
            organizationListStore.organizations &&
            organizationListStore.organizations.map(
                (organization) => organization.locations
            );

        // tslint:disable-next-line: no-unused-expression
        locations &&
            locations.forEach((location) => {
                if (location) {
                    location.forEach((item) => allLocations.push(item));
                }
            });

        return allLocations.length;
    };

    const getCountOfDevices = () => {
        const allDevices = [];
        const devices =
            organizationListStore.organizations &&
            organizationListStore.organizations.map(
                (organization) => organization.devices
            );

        // tslint:disable-next-line: no-unused-expression
        devices &&
            devices.forEach((device) => {
                if (device) {
                    device.forEach((item) => allDevices.push(item));
                }
            });

        return allDevices.length;
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.statistic}>
                <div>
                    <Title
                        title={`Кількість організацій: ${(organizationListStore.organizations &&
                            organizationListStore.organizations.length) ||
                            0}`}
                    />
                    <div>{`Кількість локацій: ${getCountOflocations() ||
                        0}`}</div>
                    <div>{`Кількість киосків: ${getCountOfDevices() ||
                        0}`}</div>
                </div>
                <Link to="/organizations/create">
                    <Button type="primary">Нова організація</Button>
                </Link>
            </div>
            <div className={styles.listWrap}>
                <OrganizationListFilter />
                <Table
                    className={styles.table}
                    locale={{ emptyText: 'Немає даних' }}
                    dataSource={getDataSource()}
                    columns={columns}
                />
            </div>
        </div>
    );
};

export default observer(OrganizationList);
