import { observable, action, runInAction } from 'mobx';

import { organizationsService } from '../services';

class OrganizationListStore {
  @observable
  public types: string[] | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async getTypes() {
    this.loading = true;

    try {
      const types = await organizationsService.getTypes();
      runInAction(() => {
        this.types = types;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new OrganizationListStore();
