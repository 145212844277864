import React from 'react';
import { Form, Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import moment from 'moment';

import { InputField, InputPassword } from '../../../common/components';

import styles from './editDeviceForm.module.scss';
import { DeviceValues } from '../../types';
import TwoColumnRow from '../../../common/components/TwoColumnRow';
import { deviceStore } from '../../stores';
import SelectField from '../LocationSelectField';
import CheckboxField from '../../../common/components/CheckboxField';
import TimePickerField from '../../../common/components/TimePickerField';
import { setFieldTouchedAsync } from '../../../common/utils';
import { organizationStore } from '../../../organizations/stores';

const EditDeviceForm = (props: FormikProps<DeviceValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'Базове посилання на сервер клієнта'}
            name="baseUrl"
            defaultValue={(deviceStore.device && deviceStore.device.properties.baseUrl) || values.baseUrl}
            onBlur={() => setFieldTouched('baseUrl')}
            errorMessage={touched.baseUrl && errors.baseUrl}
            onChange={event => setFieldValue('baseUrl', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={"Ім'я авторизації"}
            name="username"
            defaultValue={(deviceStore.device && deviceStore.device.properties.username) || values.username}
            onBlur={() => setFieldTouched('username')}
            errorMessage={touched.username && errors.username}
            onChange={event => setFieldValue('username', event.target.value)}
          />
        }
      />
      <TwoColumnRow
        firstColumn={
          <InputPassword
            title={'Пароль авторизації'}
            name="password"
            defaultValue={(deviceStore.device && deviceStore.device.properties.password) || values.password}
            onBlur={() => setFieldTouched('password')}
            errorMessage={touched.password && errors.password}
            onChange={event => setFieldValue('password', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Ідентифікатор меню'}
            name="settingId"
            defaultValue={(deviceStore.device && deviceStore.device.properties.settingId) || values.settingId}
            onBlur={() => setFieldTouched('settingId')}
            errorMessage={touched.settingId && errors.settingId}
            onChange={event => setFieldValue('settingId', event.target.value)}
          />
        }
      />

      <SelectField
        title={'Локація'}
        loading={organizationStore.loading}
        options={(organizationStore.organization && organizationStore.organization.locations) || []}
        onBlur={() => setFieldTouched('locationId')}
        errorMessage={touched.locationId && errors.locationId}
        onChange={value => {
          setFieldValue('locationId', value);
        }}
        wrapperStyle={{ margin: '0px auto 0 auto', width: '100%' }}
      />

      <div className={styles.checkboxWrap}>
        <CheckboxField
          checked={/true/i.test(values.virtual)}
          onChange={e => setFieldValue('virtual', e.target.checked)}
          label="Віртуальний кіоск"
        />

        <TwoColumnRow
          firstColumn={
            <InputField
              title={'Рахунок мерчанта'}
              disabled={!values.virtual}
              required={false}
              name="merchantAccount"
              defaultValue={
                (deviceStore.device && deviceStore.device.properties.merchantAccount) || values.merchantAccount
              }
              onBlur={() => setFieldTouched('merchantAccount')}
              errorMessage={touched.merchantAccount && errors.merchantAccount}
              onChange={event => setFieldValue('merchantAccount', event.target.value)}
            />
          }
          secondColumn={
            <InputPassword
              title={'Секретний ключ мерчанта'}
              disabled={!values.virtual}
              name="password"
              required={false}
              defaultValue={
                (deviceStore.device && deviceStore.device.properties.merchantSecretKey) || values.merchantSecretKey
              }
              onBlur={() => setFieldTouched('merchantSecretKey')}
              errorMessage={touched.merchantSecretKey && errors.merchantSecretKey}
              onChange={event => setFieldValue('merchantSecretKey', event.target.value)}
            />
          }
        />
        <TwoColumnRow
          firstColumn={
            <div className={styles.timePickerWrap}>
              <TimePickerField
                title={`Початок роботи`}
                className={styles.timePicker}
                disabled={!values.virtual}
                required={false}
                placeholder=""
                errorMessage={touched.startTime && (errors.startTime as string)}
                onOpenChange={status => !status && setFieldTouchedAsync(() => setFieldTouched('startTime'))}
                defaultValue={values.startTime ? moment(values.startTime) : undefined}
                onChange={date => setFieldValue('startTime', date ? moment(date).toString() : null)}
              />
              <TimePickerField
                placeholder=""
                title={'Кінець роботи'}
                className={styles.timePicker}
                disabled={!values.virtual}
                required={false}
                errorMessage={touched.endTime && (errors.endTime as string)}
                onOpenChange={status => !status && setFieldTouchedAsync(() => setFieldTouched('endTime'))}
                defaultValue={values.endTime ? moment(values.endTime) : undefined}
                onChange={date => setFieldValue('endTime', date ? moment(date).toString() : null)}
              />
            </div>
          }
          secondColumn={
            <div className={styles.timePickerWrap}>
              <InputField
                title={'Кількість столиків'}
                name="table"
                defaultValue={(deviceStore.device && deviceStore.device.properties.table) || values.table}
                onBlur={() => setFieldTouched('table')}
                onChange={event => setFieldValue('table', event.target.value)}
              />
              <CheckboxField
                checked={/true/i.test(values.delivery)}
                onChange={e => setFieldValue('delivery', e.target.checked)}
                label="Є доставка"
              />
            </div>
          }
        />
      </div>

      <div className={styles.buttonsWrap}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Редагувати
        </Button>
        <Button
          className={styles.button}
          onClick={() => {
            if (deviceStore.device) {
              deviceStore.delete(deviceStore.device.id);
            }
          }}
        >
          Видалити
        </Button>
      </div>
    </Form>
  );
};

const EditDeviceFormWithFormik = withFormik<any, DeviceValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    baseUrl: (deviceStore.device && deviceStore.device.properties.baseUrl) || '',
    username: (deviceStore.device && deviceStore.device.properties.username) || '',
    password: (deviceStore.device && deviceStore.device.properties.password) || '',
    settingId: (deviceStore.device && deviceStore.device.properties.settingId) || '',
    merchantAccount: (deviceStore.device && deviceStore.device.properties.merchantAccount) || '',
    merchantSecretKey: (deviceStore.device && deviceStore.device.properties.merchantSecretKey) || '',
    startTime: (deviceStore.device && deviceStore.device.properties.startTime) || '',
    endTime: (deviceStore.device && deviceStore.device.properties.endTime) || '',
    virtual: (deviceStore.device && deviceStore.device.properties.virtual) || 'false',
    delivery: (deviceStore.device && deviceStore.device.properties.delivery) || 'false',
    table: (deviceStore.device && deviceStore.device.properties.table) || 0
  }),
  handleSubmit: values => {
    if (deviceStore.device) {
      deviceStore.edit(deviceStore.device.id, {
        locationId: deviceStore.device.locationId,
        ...values
      });
    }
  },
  validationSchema: yup.object().shape<DeviceValues>({
    baseUrl: yup.string().required('Це поле не може бути порожнім!'),
    username: yup.string().required('Це поле не може бути порожнім!'),
    password: yup.string().required('Це поле не може бути порожнім!'),
    settingId: yup.string().required('Це поле не може бути порожнім!'),
    merchantAccount: yup.string().when('virtual', {
      is: true,
      then: yup.string().required('Це поле не може бути порожнім!')
    }),
    merchantSecretKey: yup.string().when('virtualt', {
      is: true,
      then: yup.string().required('Це поле не може бути порожнім!')
    }),
    startTime: yup.string(),
    endTime: yup.string(),
    virtual: yup.string(),
    delivery: yup.string(),
    table: yup.number()
  }),
  displayName: 'Edit kiosk'
})(observer(EditDeviceForm));

export default EditDeviceFormWithFormik;
