import React from 'react';
import { TimePicker } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker';

import { InputFieldTitle } from '..';

type InputFieldProps = {
  title?: string;
  errorMessage?: string | boolean;
  required?: boolean;
} & TimePickerProps;

const TimePickerField = ({ title, required, errorMessage, ...props }: InputFieldProps) => {
  return (
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage}>
      <TimePicker {...props} />
    </InputFieldTitle>
  );
};

export default TimePickerField;
