import { observable, action, runInAction } from 'mobx';

import { Menu } from '../types';
import { menusService } from '../services';

class MenuListStore {
  @observable
  public menus: Menu[] | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async loadList() {
    this.loading = true;

    try {
      const menus = await menusService.loadList();
      runInAction(() => {
        this.menus = menus;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new MenuListStore();
