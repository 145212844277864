import React from 'react';
import { Form, Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Title, InputField } from '../../../common/components';

import styles from './createAdministratorForm.module.scss';
import { administratorStore } from '../../stores';
import { AdministartorValues } from '../../types';
import { organizationStore } from '../../../organizations/stores';

const CreateAdministratorForm = (props: FormikProps<AdministartorValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Title title="Дані адміністратора" />
      <InputField
        title={'ПІБ адміністратора'}
        name="fullName"
        value={values.fullName}
        onBlur={() => setFieldTouched('fullName')}
        errorMessage={touched.fullName && errors.fullName}
        onChange={event => setFieldValue('fullName', event.target.value)}
      />
      <InputField
        title={'E-Mail адміністратора'}
        name="email"
        value={values.email}
        onBlur={() => setFieldTouched('email')}
        errorMessage={touched.email && errors.email}
        onChange={event => setFieldValue('email', event.target.value)}
      />
      <InputField
        title={'Номер телефону адміністратора'}
        placeholder="+380"
        name="phoneNumber"
        value={values.phoneNumber}
        onBlur={() => setFieldTouched('phoneNumber')}
        errorMessage={touched.phoneNumber && errors.phoneNumber}
        onChange={event => setFieldValue('phoneNumber', event.target.value)}
      />
      <InputField
        title={'Посада'}
        name="position"
        value={values.position}
        onBlur={() => setFieldTouched('position')}
        errorMessage={touched.position && errors.position}
        onChange={event => setFieldValue('position', event.target.value)}
      />
      <div className={styles.buttonsWrap}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Зберегти
        </Button>
        <Link to="/organizations">
          <Button className={styles.button}>Відміна</Button>
        </Link>
      </div>
    </Form>
  );
};

const CreateAdministratorFormWithFormik = withFormik<any, AdministartorValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    email: '',
    position: '',
    phoneNumber: '',
    fullName: '',
    activationUrl: `${window.location.origin}/administrators/confirm`
  }),
  handleSubmit: values => {
    administratorStore.create({
      ...values,
      organizationId: organizationStore.organization && organizationStore.organization.id
    });
  },
  validationSchema: yup.object().shape<AdministartorValues>({
    email: yup
      .string()
      .email()
      .required('Це поле не може бути порожнім!'),
    position: yup.string().required('Це поле не може бути порожнім!'),
    phoneNumber: yup.string().required('Це поле не може бути порожнім!'),
    fullName: yup.string().required('Це поле не може бути порожнім!')
  }),
  displayName: 'Create administrator'
})(observer(CreateAdministratorForm));

export default CreateAdministratorFormWithFormik;
