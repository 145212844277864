import React from 'react';
import { Col, Row } from 'antd';
import { RowProps } from 'antd/lib/row';

type TwoColumnRowProps = {
  firstColumn?: React.ReactNode;
  secondColumn?: React.ReactNode;
} & RowProps;

const TwoColumnRow = ({ firstColumn, secondColumn, ...props }: TwoColumnRowProps) => {
  return (
    <Row {...props}>
      <Col lg={11}>{firstColumn && firstColumn}</Col>
      <Col span={0} lg={2} />
      <Col lg={11}>{secondColumn && secondColumn}</Col>
    </Row>
  );
};

export default TwoColumnRow;
