import React from 'react';
import { Form, Button, notification } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { hashingPassword } from '../../../common/utils';
import { Title, InputPassword } from '../../../common/components';

import styles from './confirmAdministratorForm.module.scss';
import { administratorStore } from '../../stores';
import { ConfirmValues } from '../../types';

const ConfirmAdministratorForm = (props: FormikProps<ConfirmValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <Title title="Створення паролю" />
      <InputPassword
        title={'Введіть пароль'}
        name="password"
        value={values.password || ''}
        onBlur={() => setFieldTouched('password')}
        errorMessage={touched.password && errors.password}
        onChange={event => setFieldValue('password', event.target.value)}
      />
      <InputPassword
        title={'Повторіть пароль для підтвердження'}
        name="repeatPassword"
        value={values.repeatPassword}
        onBlur={() => setFieldTouched('repeatPassword')}
        errorMessage={touched.repeatPassword && errors.repeatPassword}
        onChange={event => setFieldValue('repeatPassword', event.target.value)}
      />
      <div className={styles.buttonsWrat}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Зберегти
        </Button>
        <Link to="/organizations">
          <Button className={styles.button}>Відміна</Button>
        </Link>
      </div>
    </Form>
  );
};

const ConfirmAdministratorFormWithFormik = withFormik<any, ConfirmValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    password: '',
    repeatPassword: ''
  }),
  handleSubmit: values => {
    if (values.password === values.repeatPassword) {
      administratorStore.confirm({
        password: hashingPassword.hashSHA256(values.password),
        userId: administratorStore.administrator && administratorStore.administrator.id
      });
    } else {
      notification.error({
        message: 'Помилка вводу паролю',
        description: 'Паролі не співпадають.'
      });
    }
  },
  validationSchema: yup.object().shape<ConfirmValues>({
    password: yup.string().required('Це поле не може бути порожнім!'),
    repeatPassword: yup.string().required('Це поле не може бути порожнім!')
  }),
  displayName: 'Confirm administrator'
})(observer(ConfirmAdministratorForm));

export default ConfirmAdministratorFormWithFormik;
