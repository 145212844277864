import React from 'react';
import { Layout, Menu, Row } from 'antd';
import { Link } from 'react-router-dom';

import styles from './header.module.scss';

const Header = () => {
  return (
    <Layout.Header className={styles.header}>
      <Row type="flex" justify="space-between">
        <Menu mode="horizontal" className={styles.menu}>
          <Menu.Item key="1">
            <Link to="/organizations">
              <span className={styles.linkName}>Організації</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/">
              <span className={styles.linkName}>Реклама</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/">
              <span className={styles.linkName}>Статистика</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/feedback">
              <span className={styles.linkName}>Відгуки</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Row>
    </Layout.Header>
  );
};

export default Header;
