import { observable, action, runInAction } from 'mobx';

import { advertisingsService } from '../services';

class AdvertisingStore {
  @observable
  public confirmOrder: string | null = null;
  @observable
  public whereEat: string | null = null;
  @observable
  public basket: string | null = null;
  @observable
  public confirmOrderLoading = true;
  @observable
  public whereEatLoading = true;
  @observable
  public basketLoading = true;
  @observable
  public error: Error | null = null;

  @action
  public async download(storagePath: string, type: 'CONFIRM_ORDER' | 'WHERE_EAT' | 'BASKET') {
    this.confirmOrderLoading = true;
    this.whereEatLoading = true;
    this.basketLoading = true;

    try {
      const data = await advertisingsService.download(storagePath);
      if (type === 'CONFIRM_ORDER') {
        runInAction(() => {
          this.confirmOrder = data.message;
        });
      }
      if (type === 'WHERE_EAT') {
        runInAction(() => {
          this.whereEat = data.message;
        });
      }
      if (type === 'BASKET') {
        runInAction(() => {
          this.basket = data.message;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.confirmOrder = null;
        this.whereEat = null;
        this.basket = null;
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.confirmOrderLoading = false;
        this.whereEatLoading = false;
        this.basketLoading = false;
      });
    }
  }

  @action
  public async upload(
    file: FormData,
    contentType: 'SCREEN_ADVERTISING_CONFIRM_ORDER' | 'SCREEN_ADVERTISING_WHERE_EAT' | 'SCREEN_ADVERTISING_BASKET',
    deviceId: number
  ) {
    this.confirmOrderLoading = true;
    this.whereEatLoading = true;
    this.basketLoading = true;

    try {
      if (contentType === 'SCREEN_ADVERTISING_CONFIRM_ORDER') {
        const { data } = await advertisingsService.upload(file, 'SCREEN_ADVERTISING_CONFIRM_ORDER', deviceId);
        runInAction(() => {
          this.confirmOrder = data.properties.advLinkConfirmOrder;
        });
      }
      if (contentType === 'SCREEN_ADVERTISING_WHERE_EAT') {
        const { data } = await advertisingsService.upload(file, 'SCREEN_ADVERTISING_WHERE_EAT', deviceId);
        runInAction(() => {
          this.whereEat = data.properties.advLinkWhereEat;
        });
      }
      if (contentType === 'SCREEN_ADVERTISING_BASKET') {
        const { data } = await advertisingsService.upload(file, 'SCREEN_ADVERTISING_BASKET', deviceId);
        runInAction(() => {
          this.basket = data.properties.advLinkBasket;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.confirmOrderLoading = false;
        this.whereEatLoading = false;
        this.basketLoading = false;
      });
    }
  }
}

export default new AdvertisingStore();
