import React from 'react';
import { Form, Button, Divider } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import { InputField } from '../../../common/components';

import AddMenus from '../AddMenus';
import styles from './editLocationForm.module.scss';
import { LocationValues } from '../../types';
import TwoColumnRow from '../../../common/components/TwoColumnRow';
import { locationStore, menusListStore } from '../../stores';
import MenuSelectField from '../MenuSelectField';

const EditLocationForm = (props: FormikProps<LocationValues>) => {
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'Назва локації'}
            name="name"
            defaultValue={(locationStore.location && locationStore.location.name) || values.name}
            onBlur={() => setFieldTouched('name')}
            errorMessage={touched.name && errors.name}
            onChange={event => setFieldValue('name', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'ПІБ контактної особи'}
            name="contactFio"
            defaultValue={(locationStore.location && locationStore.location.contactFio) || values.contactFio}
            onBlur={() => setFieldTouched('contactFio')}
            errorMessage={touched.contactFio && errors.contactFio}
            onChange={event => setFieldValue('contactFio', event.target.value)}
          />
        }
      />
      <TwoColumnRow
        firstColumn={
          <InputField
            title={'Адреса'}
            name="address"
            defaultValue={(locationStore.location && locationStore.location.address) || values.address}
            onBlur={() => setFieldTouched('address')}
            errorMessage={touched.address && errors.address}
            onChange={event => setFieldValue('address', event.target.value)}
          />
        }
        secondColumn={
          <InputField
            title={'Номер телефону контактної особи'}
            placeholder="+380"
            name="contactNumber"
            defaultValue={(locationStore.location && locationStore.location.contactNumber) || values.contactNumber}
            onBlur={() => setFieldTouched('contactNumber')}
            errorMessage={touched.contactNumber && errors.contactNumber}
            onChange={event => setFieldValue('contactNumber', event.target.value)}
          />
        }
      />
      <MenuSelectField
        options={menusListStore.menus}
        loading={menusListStore.loading}
        title={'Додати меню'}
        onBlur={() => setFieldTouched('menuId')}
        errorMessage={touched.menuId && errors.menuId}
        onChange={value => {
          setFieldValue('menuId', value);
        }}
        wrapperStyle={{ margin: '0px auto', width: '100%' }}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider className={styles.divider} />
            <div className={styles.iconWrap}>
              <div className={styles.icon}>
                <AddMenus props={props} />
              </div>
            </div>
          </div>
        )}
      />
      <div className={styles.buttonsWrat}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Редагувати
        </Button>
        <Button
          className={styles.button}
          onClick={() => {
            if (locationStore.location) {
              locationStore.delete(locationStore.location.id);
            }
          }}
        >
          Видалити
        </Button>
      </div>
    </Form>
  );
};

const EditLocationFormWithFormik = withFormik<any, LocationValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    menuId: (locationStore.location && locationStore.location.menuId) || null,
    address: (locationStore.location && locationStore.location.address) || '',
    contactFio: (locationStore.location && locationStore.location.contactFio) || '',
    contactNumber: (locationStore.location && locationStore.location.contactNumber) || '',
    name: (locationStore.location && locationStore.location.name) || '',
    organizationId: locationStore.location && locationStore.location.organizationId,
    tables: locationStore.location && locationStore.location.tables
  }),
  handleSubmit: values => {
    if (locationStore.location) {
      locationStore.edit(locationStore.location.id, values);
    }
  },
  validationSchema: yup.object().shape<LocationValues>({
    menuId: yup.number(),
    name: yup.string().required('Це поле не може бути порожнім!'),
    contactFio: yup.string().required('Це поле не може бути порожнім!'),
    contactNumber: yup.string().required('Це поле не може бути порожнім!'),
    address: yup.string().required('Це поле не може бути порожнім!')
  }),
  displayName: 'Edit location'
})(observer(EditLocationForm));

export default EditLocationFormWithFormik;
