import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'querystring';

import { observer } from 'mobx-react-lite';

import { Button, Table } from 'antd';

import { Title } from '../../../common/components';
import styles from './locationList.module.scss';
import LocationListFilter from '../LocationListFilter';
import { organizationStore } from '../../../organizations/stores';
import { Location } from '../../../locations/types';
import { SortOrder } from 'antd/lib/table';
const LocationList = () => {
  const currentLocation = useLocation();
  const filter = queryString.parse(currentLocation.search.substr(1, currentLocation.search.length));

  const cities : {value:string, text:string}[] = [];
  organizationStore.organization &&
  organizationStore.organization.locations &&
  organizationStore.organization.locations.forEach(({ id, name, address, devices }: Location) => {
    if(!cities.find(city=>city.value === address)){
      cities.push({value: address, text: address})
    }
  });

  const columns = [
    {
      title: 'Локація',
      align: 'center' as 'center',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Адреса',
      align: 'center' as 'center',
      dataIndex: 'address',
      key: 'address',
      filters: cities,
      onFilter: (value: any, record:  any) => record.address.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.address.localeCompare(b.address),
      sortDirections: ['descend', 'ascend'] as SortOrder[],
    },
    {
      title: 'Кількість кіосків',
      align: 'center' as 'center',
      dataIndex: 'devices',
      key: 'devices'
    },
    {
      title: '',
      align: 'center' as 'center',
      key: 'action',
      dataIndex: 'action'
    }
  ];

  const getDataSource = () => {
    const tableRows: any = [];
    // tslint:disable-next-line: no-unused-expression
    organizationStore.organization &&
      organizationStore.organization.locations &&
      organizationStore.organization.locations.forEach(({ id, name, address, devices }: Location) => {
        if (name.toLowerCase().indexOf(String(filter.search).toLowerCase()) >= 0) {
          tableRows.push({
            key: id,
            name,
            address,
            devices: (devices && devices.length) || 0,
            action: (
              <Link to={`/locations/edit/${id}`}>
                <Button type="primary">Перейти</Button>
              </Link>
            )
          });
        } else if (filter.search === undefined) {
          tableRows.push({
            key: id,
            name,
            address,
            devices: (devices && devices.length) || 0,
            action: (
              <Link to={`/locations/edit/${id}`}>
                <Button type="primary">Перейти</Button>
              </Link>
            )
          });
        }
      });
    return tableRows;
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.statistic}>
        <div>
          <Title
            title={`Кількість локацій: ${(organizationStore.organization &&
              organizationStore.organization.locations &&
              organizationStore.organization.locations.length) ||
              0}`}
          />
          <div>{`Кількість кіосків: ${(organizationStore.organization &&
            organizationStore.organization.devices &&
            organizationStore.organization.devices.length) ||
            0}`}</div>
        </div>
        <Link to="/locations/create">
          <Button type="primary">Зареєструвати нову</Button>
        </Link>
      </div>
      <div className={styles.listWrap}>
        <LocationListFilter />
        <Table
          className={styles.table}
          locale={{ emptyText: 'Немає даних' }}
          dataSource={getDataSource() || []}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default observer(LocationList);
