import React, { useEffect } from 'react';
import { Form, DatePicker, Button } from 'antd';
import { FormikProps, withFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Title, InputField, SelectField } from '../../../common/components';

import styles from './createOrganizationForm.module.scss';
import { OrganizationValues } from '../../types';
import { organizationStore, organizationTypes } from '../../stores';

const CreateOrganizationForm = (props: FormikProps<OrganizationValues>) => {
  useEffect(() => {
    organizationTypes.getTypes();
  }, []);
  const { handleSubmit, values, setFieldTouched, touched, errors, setFieldValue } = props;
  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <Title title="Дані організації" />
      <InputField
        title={'Назва організації'}
        name="name"
        value={values.name}
        onBlur={() => setFieldTouched('name')}
        errorMessage={touched.name && errors.name}
        onChange={event => setFieldValue('name', event.target.value)}
      />
      <InputField
        title={'Код ЄДРПОУ юр.особи або ФОП'}
        name="edrpou"
        value={values.edrpou}
        onBlur={() => setFieldTouched('edrpou')}
        errorMessage={touched.edrpou && errors.edrpou}
        onChange={event => setFieldValue('edrpou', event.target.value)}
      />
      <InputField
        title={'Адреса'}
        name="address"
        value={values.address}
        onBlur={() => setFieldTouched('address')}
        errorMessage={touched.address && errors.address}
        onChange={event => setFieldValue('address', event.target.value)}
      />
      <InputField
        title={'Номер договору'}
        name="contractNumber"
        value={values.contractNumber}
        onBlur={() => setFieldTouched('contractNumber')}
        errorMessage={touched.contractNumber && errors.contractNumber}
        onChange={event => setFieldValue('contractNumber', event.target.value)}
      />
      <div className={styles.dateFields}>
        <div className={styles.leftDataPicker}>
          <div>Дата складання договору:</div>
          <DatePicker
            placeholder={'Дата складання'}
            format={'YYYY/MM/DD'}
            onOpenChange={() => setFieldTouched('contractDateStart')}
            onChange={(_, dateString) => setFieldValue('contractDateStart', new Date(dateString).getTime())}
          />
        </div>
        <div className={styles.rightDataPicker}>
          <div>Термін дії договору до:</div>
          <DatePicker
            placeholder={'Термін дії до'}
            format={'YYYY/MM/DD'}
            onOpenChange={() => setFieldTouched('contractDateEnd')}
            onChange={(_, dateString) => setFieldValue('contractDateEnd', new Date(dateString).getTime())}
          />
        </div>
      </div>
      <div className={styles.autoprolong}>
        <div>Наявність автопролонгації договору:</div>
        <div className={styles.autoprolongRadio}>
          <label className={styles.label}>
            <input
              type="radio"
              name="autoProlong"
              checked={values.autoProlong === true}
              onChange={() => setFieldValue('autoProlong', true)}
            />
            Так
          </label>
          <label className={styles.label}>
            <input
              type="radio"
              name="autoProlong"
              checked={values.autoProlong === false}
              onChange={() => setFieldValue('autoProlong', false)}
            />
            Ні
          </label>
        </div>
      </div>

      <Title title="Дані керівника" />
      <InputField
        title={'ПІБ керівника'}
        name="chiefFio"
        value={values.chiefFio}
        onBlur={() => setFieldTouched('chiefFio')}
        errorMessage={touched.chiefFio && errors.chiefFio}
        onChange={event => setFieldValue('chiefFio', event.target.value)}
      />
      <InputField
        title={'Номер телефону керівника'}
        placeholder="+380"
        name="chiefNumber"
        value={values.chiefNumber}
        onBlur={() => setFieldTouched('chiefNumber')}
        errorMessage={touched.chiefNumber && errors.chiefNumber}
        onChange={event => setFieldValue('chiefNumber', event.target.value)}
      />
      <SelectField
        title={'Тип организации'}
        loading={organizationTypes.loading}
        options={organizationTypes.types || []}
        onBlur={() => setFieldTouched('type')}
        errorMessage={touched.type && errors.type}
        value={values.type}
        onChange={value => {
          setFieldValue('type', value);
        }}
        wrapperStyle={{ margin: '16px auto 0 auto', width: '98%' }}
      />

      <div className={styles.advertising}>
        <div>Є картки лояльності</div>
        <div className={styles.advertisingRadio}>
          <label className={styles.label}>
            <input
              type="radio"
              name="loyalty"
              checked={values.loyalty === true}
              onChange={() => setFieldValue('loyalty', true)}
            />
            Так
          </label>
          <label className={styles.label}>
            <input
              type="radio"
              name="loyalty"
              checked={values.loyalty === false}
              onChange={() => setFieldValue('loyalty', false)}
            />
            Ні
          </label>
        </div>
      </div>
      {values.loyalty && (
        <>
          <InputField
            title={'Посилання системи лояльності (Url)'}
            placeholder=""
            name="loyaltyUrl"
            required={false}
            onBlur={() => setFieldTouched('loyaltyUrl')}
            errorMessage={touched.loyaltyUrl && errors.loyaltyUrl}
            onChange={event => setFieldValue('loyaltyUrl', event.target.value)}
          />
          <InputField
            title={'Ключ системи лояльності'}
            placeholder=""
            required={false}
            name="loyaltyKey"
            onBlur={() => setFieldTouched('loyaltyKey')}
            errorMessage={touched.loyaltyKey && errors.loyaltyKey}
            onChange={event => setFieldValue('loyaltyKey', event.target.value)}
          />
        </>
      )}
      <div className={styles.advertising}>
        <div>Надати дозвіл для реклами?:</div>
        <div className={styles.advertisingRadio}>
          <label className={styles.label}>
            <input
              type="radio"
              name="advertising"
              checked={values.advertising === true}
              onChange={() => setFieldValue('advertising', true)}
            />
            Так
          </label>
          <label className={styles.label}>
            <input
              type="radio"
              name="advertising"
              checked={values.advertising === false}
              onChange={() => setFieldValue('advertising', false)}
            />
            Ні
          </label>
        </div>
      </div>

      <Title title="Дані касової системи" />
      <InputField
        title={'Логін'}
        required={false}
        name="oneCLogin"
        value={values.oneCLogin}
        onBlur={() => setFieldTouched('oneCLogin')}
        errorMessage={touched.oneCLogin && errors.oneCLogin}
        onChange={event => setFieldValue('oneCLogin', event.target.value)}
      />
      <InputField
        title={'Пароль'}
        required={false}
        name="oneCPass"
        value={values.oneCPass}
        onBlur={() => setFieldTouched('oneCPass')}
        errorMessage={touched.oneCPass && errors.oneCPass}
        onChange={event => setFieldValue('oneCPass', event.target.value)}
      />
      <InputField
        title={'Посилання на сервер'}
        required={false}
        name="oneCUrl"
        value={values.oneCUrl}
        onBlur={() => setFieldTouched('oneCUrl')}
        errorMessage={touched.oneCUrl && errors.oneCUrl}
        onChange={event => setFieldValue('oneCUrl', event.target.value)}
      />
      <InputField
        title={'Тип платежу (ID)'}
        required={false}
        name="oneCPaymentType"
        value={values.oneCPaymentType}
        onBlur={() => setFieldTouched('oneCPaymentType')}
        errorMessage={touched.oneCPaymentType && errors.oneCPaymentType}
        onChange={event => setFieldValue('oneCPaymentType', event.target.value)}
      />
      <div className={styles.buttonsWrat}>
        <Button type="primary" htmlType="submit" className={styles.button}>
          Зберегти
        </Button>
        <Link to="/organizations">
          <Button className={styles.button}>Відміна</Button>
        </Link>
      </div>
    </Form>
  );
};

const CreateOrganizationFormWithFormik = withFormik<any, OrganizationValues>({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    address: '',
    autoProlong: false,
    advertising: false,
    chiefFio: '',
    chiefNumber: '',
    contractDateEnd: null,
    contractDateStart: null,
    contractNumber: '',
    edrpou: '',
    name: '',
    type: '',
    oneCLogin: '',
    oneCPass: '',
    oneCUrl: '',
    oneCPaymentType: '',
    loyalty: false,
    loyaltyUrl: '',
    loyaltyKey: ''
  }),
  handleSubmit: values => {
    organizationStore.create(values);
  },
  validationSchema: yup.object().shape<OrganizationValues>({
    name: yup.string().required('Це поле не може бути порожнім!'),
    edrpou: yup.string().required('Це поле не може бути порожнім!'),
    address: yup.string().required('Це поле не може бути порожнім!'),
    contractNumber: yup.string().required('Це поле не може бути порожнім!'),
    contractDateEnd: yup.number().required('Це поле не може бути порожнім!'),
    contractDateStart: yup.number().required('Це поле не може бути порожнім!'),
    autoProlong: yup.boolean().required('Це поле не може бути порожнім!'),
    advertising: yup.boolean().required('Це поле не може бути порожнім!'),
    chiefFio: yup.string().required('Це поле не може бути порожнім!'),
    chiefNumber: yup.string().required('Це поле не може бути порожнім!'),
    loyalty: yup.boolean().required('Це поле не може бути порожнім!'),
    loyaltyUrl: yup.string(),
    loyaltyKey: yup.string()
  }),
  displayName: 'Create organization'
})(observer(CreateOrganizationForm));

export default CreateOrganizationFormWithFormik;
