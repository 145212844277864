import * as React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Button, Table, Icon } from 'antd';

import { Title } from '../../../common/components';
import styles from './devicesList.module.scss';
import { locationStore } from '../../../locations/stores';
import { Device } from '../../types';
import { organizationStore } from '../../../organizations/stores';
import { deviceStore } from '../../stores';

const DevicesList = () => {
    const columns = [
        {
            title: "Унікальне ім'я кіоску",
            align: 'center' as 'center',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'URL адреса 1С',
            align: 'center' as 'center',
            dataIndex: 'baseUrl',
            key: 'baseUrl',
        },
        {
            title: 'Статус рекламних екранів',
            align: 'center' as 'center',
            dataIndex: 'advertising',
            key: 'advertising',
        },
        {
            title: '',
            align: 'center' as 'center',
            key: 'action',
            dataIndex: 'action',
        },
    ];

    const getDataSource = () => {
        if (locationStore.location && locationStore.location.devices) {
            return locationStore.location.devices.map(
                ({ id, properties }: Device) => {
                    return {
                        key: id,
                        name: properties.name,
                        baseUrl: properties.baseUrl,
                        advertising: (
                            <div>
                                {properties.advStatusBasket === 'true' &&
                                properties.advStatusConfirmOrder === 'true' &&
                                properties.advStatusWhereEat === 'true' &&
                                organizationStore.organization &&
                                organizationStore.organization.advertising ? (
                                    <div className={styles.advertisingWrap}>
                                        <div
                                            className={styles.advertisingStatus}
                                        >
                                            {properties.advLinkBasket !==
                                            'persigned-minio-url' ? (
                                                <Icon type="eye" />
                                            ) : (
                                                <Icon type="minus" />
                                            )}
                                        </div>
                                        <div
                                            className={styles.advertisingStatus}
                                        >
                                            {properties.advLinkConfirmOrder !==
                                            'persigned-minio-url' ? (
                                                <Icon type="eye" />
                                            ) : (
                                                <Icon type="minus" />
                                            )}
                                        </div>
                                        <div
                                            className={styles.advertisingStatus}
                                        >
                                            {properties.advLinkWhereEat !==
                                            'persigned-minio-url' ? (
                                                <Icon type="eye" />
                                            ) : (
                                                <Icon type="minus" />
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.advertisingWrap}>
                                        <div
                                            className={styles.advertisingStatus}
                                        >
                                            <Icon type="close" />
                                        </div>
                                        <div
                                            className={styles.advertisingStatus}
                                        >
                                            <Icon type="close" />
                                        </div>
                                        <div
                                            className={styles.advertisingStatus}
                                        >
                                            <Icon type="close" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ),
                        action: (
                            <div className={styles.buttonsWrap}>
                                {properties.advStatusBasket === 'true' &&
                                properties.advStatusConfirmOrder === 'true' &&
                                properties.advStatusWhereEat === 'true' ? (
                                    <Button
                                        className={styles.buttonStop}
                                        onClick={() => {
                                            deviceStore.edit(id, {
                                                locationId:
                                                    locationStore.location &&
                                                    locationStore.location.id,
                                                baseUrl: properties.baseUrl,
                                                username: properties.username,
                                                password: properties.password,
                                                settingId: properties.settingId,
                                                advStatusConfirmOrder: 'false',
                                                advLinkConfirmOrder:
                                                    properties.advLinkConfirmOrder,
                                                advStatusWhereEat: 'false',
                                                advLinkWhereEat:
                                                    properties.advLinkWhereEat,
                                                advStatusBasket: 'false',
                                                advLinkBasket:
                                                    properties.advLinkBasket,
                                                merchantAccount:
                                                    properties.merchantAccount,
                                                merchantSecretKey:
                                                    properties.merchantSecretKey,
                                                startTime: properties.startTime,
                                                endTime: properties.endTime,
                                                virtual: properties.virtual,
                                                delivery: properties.delivery,
                                                table: properties.table,
                                            });
                                        }}
                                        type="primary"
                                    >
                                        Призупинити
                                    </Button>
                                ) : organizationStore.organization &&
                                  organizationStore.organization.advertising ? (
                                    <Button
                                        className={styles.buttonActive}
                                        onClick={() => {
                                            deviceStore.edit(id, {
                                                locationId:
                                                    locationStore.location &&
                                                    locationStore.location.id,
                                                baseUrl: properties.baseUrl,
                                                username: properties.username,
                                                password: properties.password,
                                                settingId: properties.settingId,
                                                advStatusConfirmOrder: 'true',
                                                advLinkConfirmOrder:
                                                    properties.advLinkConfirmOrder,
                                                advStatusWhereEat: 'true',
                                                advLinkWhereEat:
                                                    properties.advLinkWhereEat,
                                                advStatusBasket: 'true',
                                                advLinkBasket:
                                                    properties.advLinkBasket,
                                                merchantAccount:
                                                    properties.merchantAccount,
                                                merchantSecretKey:
                                                    properties.merchantSecretKey,
                                                startTime: properties.startTime,
                                                endTime: properties.endTime,
                                                virtual: properties.virtual,
                                                delivery: properties.delivery,
                                                table: properties.table,
                                            });
                                        }}
                                        type="primary"
                                    >
                                        Активувати
                                    </Button>
                                ) : (
                                    <Button
                                        className={styles.buttonActive}
                                        disabled={true}
                                        type="primary"
                                    >
                                        Активувати
                                    </Button>
                                )}
                                <Link to={`/devices/edit/${id}`}>
                                    <Button type="primary">Перейти</Button>
                                </Link>
                            </div>
                        ),
                    };
                }
            );
        }
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.statistic}>
                <div>
                    <Title
                        title={`Кількість кіосків: ${(locationStore.location &&
                            locationStore.location.devices &&
                            locationStore.location.devices.length) ||
                            0}`}
                    />
                </div>
                <Link to="/devices/create">
                    <Button type="primary">Зареєструвати новий</Button>
                </Link>
            </div>
            <div className={styles.listWrap}>
                <Table
                    className={styles.table}
                    locale={{ emptyText: 'Немає даних' }}
                    dataSource={getDataSource()}
                    columns={columns}
                />
            </div>
        </div>
    );
};

export default observer(DevicesList);
