import axios from 'axios';
import { notification, message } from 'antd';

import settings from '../../../settings';
import { DeviceValues, Device } from '../types';
import { deviceStore } from '../stores';
import { locationStore } from '../../locations/stores';

class DevicesService {
    public load = async (deviceId: number): Promise<Device> => {
        try {
            const { data } = await axios.get(
                `${settings.domain}/api/admin/device/${deviceId}`
            );
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description:
                    'Не вдалося завантажити інформацію про кіоск. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public create = async (locationId: number, formValues: DeviceValues) => {
        try {
            const { data } = await axios.post(
                `${settings.domain}/api/admin/device/${locationId}`,
                {
                    organizationId:
                        locationStore.location &&
                        locationStore.location.organizationId,
                    properties: {
                        baseUrl: formValues.baseUrl,
                        username: formValues.username,
                        password: formValues.password,
                        settingId: formValues.settingId,
                        advStatusConfirmOrder: 'false',
                        advLinkConfirmOrder: 'persigned-minio-url',
                        advStatusWhereEat: 'false',
                        advLinkWhereEat: 'persigned-minio-url',
                        advStatusBasket: 'false',
                        advLinkBasket: 'persigned-minio-url',
                        merchantAccount: formValues.merchantAccount,
                        merchantSecretKey: formValues.merchantSecretKey,
                        startTime: formValues.startTime,
                        endTime: formValues.endTime,
                        virtual: formValues.virtual,
                        delivery: formValues.delivery,
                        table: formValues.table,
                    },
                }
            );
            message.success('Кіоск успішно створений!', 5);
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description: 'Не вдалося створити кіоск. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public edit = async (deviceId: number, formValues: DeviceValues) => {
        try {
            const { data } = await axios.put(
                `${settings.domain}/api/admin/device/${deviceId}`,
                {
                    locationId: formValues.locationId,
                    properties: {
                        baseUrl: formValues.baseUrl,
                        username: formValues.username,
                        password: formValues.password,
                        settingId: formValues.settingId,
                        advStatusConfirmOrder:
                            formValues.advStatusConfirmOrder ||
                            (deviceStore.device &&
                                deviceStore.device.advStatusConfirmOrder),
                        advLinkConfirmOrder:
                            formValues.advLinkConfirmOrder ||
                            (deviceStore.device &&
                                deviceStore.device.advLinkConfirmOrder),
                        advStatusWhereEat:
                            formValues.advStatusWhereEat ||
                            (deviceStore.device &&
                                deviceStore.device.advStatusWhereEat),
                        advLinkWhereEat:
                            formValues.advLinkWhereEat ||
                            (deviceStore.device &&
                                deviceStore.device.advLinkWhereEat),
                        advStatusBasket:
                            formValues.advStatusBasket ||
                            (deviceStore.device &&
                                deviceStore.device.advStatusBasket),
                        advLinkBasket:
                            formValues.advLinkBasket ||
                            (deviceStore.device &&
                                deviceStore.device.advLinkBasket),
                        merchantAccount: formValues.merchantAccount,
                        merchantSecretKey: formValues.merchantSecretKey,
                        startTime: formValues.startTime,
                        endTime: formValues.endTime,
                        virtual: formValues.virtual,
                        delivery: formValues.delivery,
                        table: formValues.table,
                    },
                }
            );
            message.success('Кіоск успішно оновлений!', 5);
            return data.data;
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description: 'Не вдалося оновити кіоск. Спробуйте пізніше.',
            });
            throw error;
        }
    };

    public delete = async (deviceId: number) => {
        try {
            await axios.delete(
                `${settings.domain}/api/admin/device/${deviceId}`
            );
            message.success('Кіоск успішно видалений!', 5);
        } catch (error) {
            notification.error({
                message: 'Серверна помилка',
                description: 'Не вдалося видалити кіоск. Спробуйте пізніше.',
            });
            throw error;
        }
    };
}

export default new DevicesService();
