import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import styles from './selectField.module.scss';
import { InputFieldTitle } from '../index';

type SelectFieldProps = {
  title?: string;
  defaultValue?: string;
  wrapperStyle?: React.CSSProperties;
  options: string[];
  errorMessage?: string | boolean;
  loading?: boolean;
  required?: boolean;
} & SelectProps;

const SelectField = ({
  title,
  defaultValue,
  required,
  errorMessage,
  options,
  loading,
  wrapperStyle,
  ...props
}: SelectFieldProps) => {
  return (
    <InputFieldTitle required={required} title={title || ''} errorMessage={errorMessage} style={wrapperStyle}>
      <Select {...props} className={styles.select} loading={loading} defaultValue={defaultValue}>
        {options.map((option: string) => (
          <Select.Option key={option} value={option} title={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </InputFieldTitle>
  );
};

export default SelectField;
