/* eslint-disable no-unexpected-multiline */
import React from 'react';
import { Upload, Icon, notification, Popover, Alert, Spin, Button } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';

import { advertisingStore, deviceStore } from '../../stores';

import './advertisingWhereEat.global.scss';
import styles from './advertisingWhereEat.module.scss';

type LoadImgState = {
    url: string | null;
    loading: boolean;
    originFile: any;
} & UploadProps;

interface TypeImgState {
    type: string | null;
}

interface LoadImgProps {
    title?: string;
    style?: string;
}

interface File {
    type: string;
    size: number;
}

const AdvertisingWhereEat = ({ title, style }: LoadImgProps) => {
    const [fileType, setFileType] = React.useState<TypeImgState>({
        type: null,
    });
    const [file, setFile] = React.useState<LoadImgState>({
        originFile: null,
        url: null,
        loading: false,
    });

    const getBase64 = (img: Blob, callback: CallableFunction) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (uploadFile: File) => {
        const isJpgOrPng =
            uploadFile.type === 'image/jpeg' || uploadFile.type === 'image/png';
        const isLt2M = uploadFile.size / 1024 / 1024 < 2;
        const isLt100M = uploadFile.size / 1024 / 1024 < 100;
        if (!isLt100M) {
            notification.error({
                message: 'Помилка завантаження файлу',
                description: 'Відео має бути меньше ніж 100MB!',
                duration: 0,
            });
        }
        if (!isLt2M && isJpgOrPng) {
            notification.error({
                message: 'Помилка завантаження файлу',
                description:
                    'Зображення має бути меньше ніж 2MB та тільки png/jpeg!',
                duration: 0,
            });
        }
        if (isLt2M && isJpgOrPng) {
            setFileType({ type: 'image' });
            return isLt2M;
        }
        if (isLt100M) {
            setFileType({ type: 'video' });
            return isLt100M;
        }
        return isLt100M;
    };

    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setFile({ originFile: null, url: null, loading: true });
            return;
        }

        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (imgUrl: string) =>
                setFile({
                    originFile: info.file.originFileObj,
                    url: imgUrl,
                    loading: false,
                })
            );
        }
    };

    const uploadButton = (
        <div className={style}>
            <div>
                <Icon
                    className={styles.uploadIcon}
                    type={file.loading ? 'loading' : 'plus'}
                />
                <div>{title}</div>
            </div>
        </div>
    );

    const { url, originFile } = file;
    const { type } = fileType;
    if (originFile) {
        const formdata = new FormData();
        formdata.append('file', originFile);
        if ((type === 'image' || type === 'video') && deviceStore.device) {
            advertisingStore.upload(
                formdata,
                'SCREEN_ADVERTISING_WHERE_EAT',
                deviceStore.device.id
            );
        }
    }

    const getImg = () => {
        if (url) {
            if (deviceStore.device && type === 'image') {
                return <img src={url} alt="file img/video" className={style} />;
            } else {
                return (
                    <div>
                        <Icon type="play-circle" />
                    </div>
                );
            }
        } else if (
            advertisingStore.whereEat &&
            advertisingStore.basket !== 'persigned-minio-url'
        ) {
            if (advertisingStore.whereEatLoading) {
                return <Spin />;
            } else {
                // tslint:disable-next-line: no-shadowed-variable
                const fileType: string = advertisingStore.whereEat
                    .split('/')
                    [
                        advertisingStore.whereEat &&
                            advertisingStore.whereEat.split('/').length - 1
                    ].split('?')[0]
                    .split('.')[1];
                if (fileType === 'png' || fileType === 'jpeg') {
                    return (
                        <img
                            src={advertisingStore.whereEat}
                            alt="file img/video"
                            className={style}
                        />
                    );
                } else {
                    return (
                        <div>
                            <Icon type="play-circle" />
                        </div>
                    );
                }
            }
        } else {
            return uploadButton;
        }
    };

    return (
        <div className="imgWrap">
            <div className={styles.title}>Де поїсти?</div>
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                <Popover
                    className={styles.popover}
                    content={
                        <div
                            className={styles.popoverContent}
                        >{`Завантажений файл: ${advertisingStore.whereEat &&
                            advertisingStore.whereEat
                                .split('/')
                                [
                                    advertisingStore.whereEat &&
                                        advertisingStore.whereEat.split('/')
                                            .length - 1
                                ].split('?')[0]}`}</div>
                    }
                    placement="topRight"
                    title={
                        <Alert
                            message="Для завантаження фону кіоску застосовуються зображення та відео!"
                            type="info"
                            showIcon={true}
                        />
                    }
                    trigger="hover"
                >
                    {getImg()}
                </Popover>
            </Upload>
            <Button
                className={styles.button}
                onClick={() => {
                    if (deviceStore.device) {
                        deviceStore.edit(deviceStore.device.id, {
                            locationId: deviceStore.device.locationId,
                            baseUrl: deviceStore.device.properties.baseUrl,
                            username: deviceStore.device.properties.username,
                            password: deviceStore.device.properties.password,
                            settingId: deviceStore.device.properties.settingId,
                            advStatusConfirmOrder: 'true',
                            advLinkConfirmOrder:
                                deviceStore.device.properties
                                    .advLinkConfirmOrder,
                            advStatusWhereEat: 'true',
                            advLinkWhereEat: 'persigned-minio-url',
                            advStatusBasket: 'true',
                            advLinkBasket:
                                deviceStore.device.properties.advLinkBasket,
                            merchantAccount:
                                deviceStore.device.properties.merchantAccount,
                            merchantSecretKey:
                                deviceStore.device.properties.merchantSecretKey,
                            startTime: deviceStore.device.properties.startTime,
                            endTime: deviceStore.device.properties.endTime,
                            virtual: deviceStore.device.virtual,
                            delivery: deviceStore.device.delivery,
                            table: deviceStore.device.table,
                        });
                    }
                }}
            >
                Відключити баннер
            </Button>
        </div>
    );
};

export default observer(AdvertisingWhereEat);
