import axios from 'axios';
import { notification, message } from 'antd';

import { Menu, MenuValues } from '../types';
import settings from '../../../settings';

class MenusService {
  public loadList = async (): Promise<Menu[]> => {
    try {
      const { data } = await axios.get(`${settings.domain}/api/admin/menu/all`);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити список меню. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public load = async (menuId: number): Promise<Menu> => {
    try {
      const { data } = await axios.get(`${settings.domain}/api/admin/menu/${menuId}`);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити меню. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public create = async (formValues: MenuValues) => {
    try {
      const { data } = await axios.post(`${settings.domain}/api/admin/menu`, {
        publish: true,
        tax: 0,
        description: '',
        ...formValues
      });
      message.success('Меню успішно створено!', 5);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося створити меню. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public edit = async (menuId: number, formValues: MenuValues) => {
    try {
      const { data } = await axios.put(`${settings.domain}/api/admin/menu/${menuId}`, {
        publish: true,
        tax: 0,
        description: '',
        ...formValues
      });
      message.success('Меню успішно оновлено!', 5);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося оновити меню. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public delete = async (menuId: number) => {
    try {
      await axios.delete(`${settings.domain}/api/admin/menu/${menuId}`);
      message.success('Меню успішно видалено!', 5);
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося видалити меню. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new MenusService();
