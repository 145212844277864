import axios from 'axios';
import { notification, message } from 'antd';

import settings from '../../../settings';

interface RefreshLocationsProps {
  organizationId: number;
  menuId: number;
}

class RefreshLocations {
  public refresh = async (values: RefreshLocationsProps) => {
    try {
      console.log(`${values.organizationId}/${values.menuId}`)
      const { data } = await axios.get(
        `${settings.domain}/api/database/locations/${values.organizationId}/${values.menuId}`
      );
      message.success('Організація успішно оновлена!', 5);
      return data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося оновити список локацій. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new RefreshLocations();
