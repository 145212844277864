import { observable, action, runInAction } from 'mobx';

import { menusService } from '../services';
import { Menu, MenuValues } from '../types';
import { menusListStore } from '.';

class MenuStore {
  @observable
  public menu: Menu | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async load(menuId: number) {
    this.loading = true;

    try {
      const menu = await menusService.load(menuId);
      runInAction(() => {
        this.menu = menu;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async create(formValues: MenuValues) {
    this.loading = true;

    try {
      const menu = await menusService.create(formValues);
      runInAction(() => {
        this.menu = menu;
      });
      menusListStore.loadList();
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async edit(menuId: number, formValues: MenuValues) {
    this.loading = true;

    try {
      const menu = await menusService.edit(menuId, formValues);
      runInAction(() => {
        this.menu = menu;
      });
      menusListStore.loadList();
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async delete(menuId: number) {
    this.loading = true;

    try {
      await menusService.delete(menuId);
      menusListStore.loadList();
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new MenuStore();
