import { observable, action, runInAction } from 'mobx';

import history from '../../../history';
import { devicesService } from '../services';
import { DeviceValues, Device } from '../types';
import { locationStore } from '../../locations/stores';
import { organizationStore } from '../../organizations/stores';
import { advertisingStore } from '.';

class DeviceStore {
  @observable
  public device: Device | null = null;
  @observable
  public loading = true;
  @observable
  public error: Error | null = null;

  @action
  public async load(deviceId: number) {
    this.loading = true;

    try {
      const device = await devicesService.load(deviceId);
      runInAction(() => {
        this.device = device;
      });
      if (this.device && this.device.locationId) {
        locationStore.load(this.device.locationId);
      }
      if (this.device && this.device.organizationId) {
        organizationStore.load(this.device.organizationId);
      }
      if (this.device && this.device.properties && this.device.properties.advLinkBasket) {
        advertisingStore.download(this.device.properties.advLinkBasket, 'BASKET');
      }
      if (this.device && this.device.properties && this.device.properties.advLinkConfirmOrder) {
        advertisingStore.download(this.device.properties.advLinkConfirmOrder, 'CONFIRM_ORDER');
      }
      if (this.device && this.device.properties && this.device.properties.advLinkWhereEat) {
        advertisingStore.download(this.device.properties.advLinkWhereEat, 'WHERE_EAT');
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async create(locationId: number, formValues: DeviceValues) {
    this.loading = true;

    try {
      const device = await devicesService.create(locationId, formValues);
      runInAction(() => {
        this.device = device;
      });
      history.push(`/locations/edit/${locationStore.location && locationStore.location.id}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async edit(deviceId: number, formValues: DeviceValues) {
    this.loading = true;

    try {
      const device = await devicesService.edit(deviceId, formValues);
      runInAction(() => {
        this.device = device;
      });
      if (this.device) {
        devicesService.load(this.device.id);
      }
      if (locationStore.location) {
        locationStore.load(locationStore.location.id);
      }
      if (this.device && this.device.properties && this.device.properties.advLinkBasket) {
        advertisingStore.download(this.device.properties.advLinkBasket, 'BASKET');
      }
      if (this.device && this.device.properties && this.device.properties.advLinkConfirmOrder) {
        advertisingStore.download(this.device.properties.advLinkConfirmOrder, 'CONFIRM_ORDER');
      }
      if (this.device && this.device.properties && this.device.properties.advLinkWhereEat) {
        advertisingStore.download(this.device.properties.advLinkWhereEat, 'WHERE_EAT');
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  public async delete(deviceId: number) {
    this.loading = true;

    try {
      await devicesService.delete(deviceId);
      history.push(`/locations/edit/${locationStore.location && locationStore.location.id}`);
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export default new DeviceStore();
