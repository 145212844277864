import axios from 'axios';
import { notification, message } from 'antd';

import { AdministartorValues, ConfirmValues } from '../types';
import settings from '../../../settings';

class AdministratorsService {
  public load = async (administratorId: number) => {
    try {
      const { data } = await axios.get(`${settings.domain}/api/admin/user/${administratorId}`);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося завантажити дані адміністратора. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public create = async (formValues: AdministartorValues) => {
    try {
      const { data } = await axios.post(`${settings.domain}/api/admin/user`, formValues);
      if (data.message === 'User with such username already exist') {
        message.error('Неможливо створити адміністратора. Такий адміністратор вже існує.', 5);
      } else {
        message.success('Адміністратор успішно створений! Підтвердження відправлено на email', 5);
        return data.data;
      }
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося створити адміністратора. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public confirm = async (formValues: ConfirmValues) => {
    try {
      const { data } = await axios.put(
        `${settings.domain}/api/admin/user/activation?userId=${formValues.userId}&password=${formValues.password}`
      );
      message.success('Адміністратор успішно активований!', 5);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося активувати адміністратора. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public edit = async (administratorId: number, formValues: AdministartorValues) => {
    try {
      const { data } = await axios.put(`${settings.domain}/api/admin/user/${administratorId}`, formValues);
      message.success('Адміністратор успішно оновлений!', 5);
      return data.data;
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося редагувати адміністратора. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public delete = async (administratorId: number) => {
    try {
      await axios.delete(`${settings.domain}/api/admin/user/${administratorId}`);
      message.success('Адміністратор успішно видалений!', 5);
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося видалити адміністратора. Спробуйте пізніше.'
      });
      throw error;
    }
  };

  public addLocation = async (administratorId: number, locationIds: number[]) => {
    try {
      await axios.patch(`${settings.domain}/api/admin/user/${administratorId}`, locationIds);
    } catch (error) {
      notification.error({
        message: 'Серверна помилка',
        description: 'Не вдалося додати локації до адміністратора. Спробуйте пізніше.'
      });
      throw error;
    }
  };
}

export default new AdministratorsService();
