import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Menu as MenuAntd } from 'antd';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs, Title } from '../../../common/components';
import styles from './edit.module.scss';
import { organizationStore } from '../../../organizations/stores';
import { locationStore, locationsListStore } from '../../../locations/stores';
import EditDeviceForm from '../../components/EditDeviceForm';
import { deviceStore } from '../../stores';
import AdvertisingConfirm from '../../components/AdvertisingConfirm';
import AdvertisingWhereEat from '../../components/AdvertisingWhereEat';
import AdvertisingAddItemToCart from '../../components/AdvertisingAddItemToCart';

const { SubMenu } = MenuAntd;

const Edit = ({ match }: RouteComponentProps<{ id: string }>) => {
  useEffect(() => {
    deviceStore.load(Number(match.params.id));
    locationsListStore.loadList();
  }, [match.params.id]);

  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Локація`}</title>
        <meta name="description" content={`${settings.appName} - Локація`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' },
            {
              name: `${organizationStore.organization && organizationStore.organization.name}`,
              url: `/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`
            },
            {
              name: `${locationStore.location && locationStore.location.name}`,
              url: `/locations/edit/${locationStore.location && locationStore.location.id}`
            },
            {
              name: `${deviceStore.device && deviceStore.device.properties.name}`,
              url: `/devices/edit/${deviceStore.device && deviceStore.device.id}`
            }
          ]}
        />
        <div className={styles.info}>
          <div className={styles.titleWrap}>
            <Title title={`Кіоск: ${deviceStore.device && deviceStore.device.properties.name}`} />
            <div>{`Мережа - ${locationStore.location && locationStore.location.name}`}</div>
          </div>
          <div>
            <MenuAntd defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} mode="inline">
              <SubMenu className={styles.subMenu} key="sub1" title={<Title title="Інформація" />}>
                <div className={styles.subMenuContent}>{deviceStore.device ? <EditDeviceForm /> : null}</div>
              </SubMenu>
            </MenuAntd>
          </div>
          {deviceStore.device && organizationStore.organization && organizationStore.organization.advertising ? (
            <div className={styles.advertisingWrap}>
              {deviceStore.device.properties.advStatusConfirmOrder === 'true' ? <AdvertisingConfirm /> : null}
              {deviceStore.device.properties.advStatusWhereEat === 'true' ? <AdvertisingWhereEat /> : null}
              {deviceStore.device.properties.advStatusBasket === 'true' ? <AdvertisingAddItemToCart /> : null}
            </div>
          ) : null}
        </div>
      </div>
    </AppLayout>
  );
};

export default observer(Edit);
