import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Alert } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import settings from '../../../../settings';
import { AppLayout, Breadcrumbs } from '../../../common/components';
import styles from './confirm.module.scss';
import ConfirmAdministratorForm from '../../components/ConfirmAdministratorForm';
import { organizationStore } from '../../../organizations/stores';
import { administratorStore } from '../../stores';

const Confirm = ({ match }: RouteComponentProps<{ id: string }>) => {
  useEffect(() => {
    administratorStore.load(Number(match.params.id));
  }, [match.params.id]);

  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Підтвердження регістрації`}</title>
        <meta name="description" content={`${settings.appName} - Підтвердження регістрації`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' },
            {
              name: `${organizationStore.organization && organizationStore.organization.name}`,
              url: `/organizations/edit/${organizationStore.organization && organizationStore.organization.id}`
            },
            { name: 'Форма реєстрації адміністратора', url: '/administrators/confirm' }
          ]}
        />
        <div className={styles.fromWrap}>
          <Alert
            message="Заповнить форму щоб додати нового адміністратора. Всі поля обов’язкові до заповнення."
            type="info"
            showIcon={true}
          />
          <ConfirmAdministratorForm />
        </div>
      </div>
    </AppLayout>
  );
};

export default observer(Confirm);
