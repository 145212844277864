import { observable, action, runInAction } from 'mobx';

import { organizationsService } from '../services';
import { Organization } from '../types';
import { changeArrayElementOrder, sortOrder } from '../../common/utils';

class OrganizationListStore {
    @observable
    public organizations: Organization[] | null = null;
    @observable
    public loading = true;
    @observable
    public updateOrganizationOrderLoading = false;
    @observable
    public error: Error | null = null;

    @action
    public async loadList() {
        this.loading = true;

        try {
            const organizations = await organizationsService.loadList();
            runInAction(() => {
                this.organizations = organizations;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error;
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action public async changeOrganizationOrder(
        direction: 'UP' | 'DOWN',
        organizationId: number,
        organizationOrder: number
    ) {
        if (!this.organizations) {
            return false;
        }

        try {
            this.updateOrganizationOrderLoading = true;

            const newArreyOfOrganizations = changeArrayElementOrder(
                this.organizations,
                organizationId,
                direction
            );

            const activeOrganization = newArreyOfOrganizations.find(
                (tempOrganization) => tempOrganization.id === organizationId
            );
            const newOrganization = newArreyOfOrganizations.find(
                (tempOrganization) =>
                    tempOrganization.order === organizationOrder
            );

            await Promise.all([
                organizationsService.updateWithoutRedirect(
                    activeOrganization.id,
                    newOrganization.id
                ),
            ]);

            runInAction(() => {
                this.organizations = sortOrder(
                    newArreyOfOrganizations,
                    'order'
                );
            });
        } finally {
            runInAction(() => {
                this.updateOrganizationOrderLoading = false;
            });
        }
    }
}

export default new OrganizationListStore();
