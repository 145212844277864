import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Menu as MenuAntd, Button, Alert } from 'antd';
import { RouteComponentProps, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import EditOrganizationForm from '../../components/EditOrganizationForm';
import EditAdministratorForm from '../../../administrators/components/EditAdministratorForm';
import settings from '../../../../settings';
import { AppLayout, Breadcrumbs, Title } from '../../../common/components';
import styles from './edit.module.scss';
import LocationList from '../../../locations/components/LocationList';
import { organizationStore } from '../../stores';

const { SubMenu } = MenuAntd;

const Edit = ({ match }: RouteComponentProps<{ id: string }>) => {
  useEffect(() => {
    organizationStore.load(Number(match.params.id));
  }, [match.params.id]);

  return (
    <AppLayout>
      <Helmet>
        <title>{`${settings.appName} - Організація`}</title>
        <meta name="description" content={`${settings.appName} - Організація`} />
      </Helmet>
      <div className={styles.wrap}>
        <Breadcrumbs
          links={[
            { name: 'Головна', url: '/' },
            { name: 'Організації', url: '/organizations' },
            {
              name: `${organizationStore.organization && organizationStore.organization.name}`,
              url: `${organizationStore.organization && organizationStore.organization.id}`
            }
          ]}
        />
        <div className={styles.info}>
          <div className={styles.titleWrap}>
            <Title title={`${organizationStore.organization && organizationStore.organization.name}`} />
          </div>
          <MenuAntd defaultSelectedKeys={['0']} defaultOpenKeys={['sub0']} mode="inline">
            <SubMenu className={styles.subMenu} key="sub1" title={<Title title="Інформація" />}>
              <div className={styles.subMenuContent}>
                <EditOrganizationForm />
              </div>
            </SubMenu>

            {organizationStore.organization && organizationStore.organization.users ? (
              organizationStore.organization.users.map(user => (
                <SubMenu
                  className={styles.subMenu}
                  key={`sub3${user.id}`}
                  title={<Title title={`Адміністратор ( ${user.email} )`} />}
                >
                  <div className={styles.subMenuContent}>
                    <EditAdministratorForm administrator={user} />
                  </div>
                </SubMenu>
              ))
            ) : (
              <SubMenu className={styles.subMenu} key="sub2" title={<Title title="Адміністратор" />}>
                <div className={styles.subMenuContent}>
                  <div className={styles.addAdmin}>
                    <Alert
                      message="Додайте Адміністратора, який буде працювати в системі від імені даної організації"
                      type="info"
                      showIcon={true}
                    />
                    <Link to="/administrators/create">
                      <Button className={styles.button} type="primary">
                        Додати адміністратора
                      </Button>
                    </Link>
                  </div>
                </div>
              </SubMenu>
            )}
          </MenuAntd>
          <LocationList />
        </div>
      </div>
    </AppLayout>
  );
};

export default observer(Edit);
